import { useEffect, useState, Fragment } from 'react'
import { Modal, Button, LoadingIcons, Fa } from '@eltoro-ui/components'
import {
  Campaignservicev1OrderLine,
  Creativeservicev1Creative,
  V1Results,
  V1StatsResponse,
} from 'next-gen-sdk'
import { CreativeResType, StatsAccumulatorType } from 'types'
import { dayjs } from 'Tools/dateUtils'
import { creativeStatsFixture } from 'Fixtures'
import { getJobQuotes } from 'Requests'
import { styleTailwind } from 'Helpers'
import { useAppContext } from 'Contexts'
import { getOrderLineStats } from 'Helpers/getOrderLineStats'
import { Stats } from './Stats'
import { CSVColumnPickerModal } from './DownloadCSVModal'
import {
  creativeStatMaker,
  statMakerForEnvironment,
} from './Stats/statMockData'
import { downloadPDFReport } from './downloadPDFReport'
import './ReportModal.scss'

const ActionButton = styleTailwind(
  'span',
  'ActionButton group px-4 flex h-8 w-44 focus:ring transition-all items-center justify-center',
)

export type DailyBreakdownType = 'clicks' | 'impressions' | 'ctr'
type ReportModalType = {
  orderline: Campaignservicev1OrderLine
  closeModal: () => void
}
export const ReportModal = ({ orderline, closeModal }: ReportModalType) => {
  const [load, setLoad] = useState<boolean>(false)
  const [loadCSV, setLoadCSV] = useState<boolean>(false)
  const [week, setWeek] = useState(0)
  const [item, setItem] = useState<DailyBreakdownType>('clicks')
  const [olStatsData, setOlStatsData] = useState<V1StatsResponse>()
  const [creativeStatsData, setCreativeStatsData] = useState<V1StatsResponse>(
    creativeStatsFixture,
  )
  const [sortedCreativeStats, setSortedCreativeStats] = useState<
    StatsAccumulatorType[]
  >([])
  const [totalAudiences, setTotalIncludedAudiences] = useState<number>()
  const [totalExcluded, setTotalExcludedAudiences] = useState<number>()
  const [columnModal, setColumnModal] = useState(false)
  const [creatives, setCreatives] = useState<CreativeResType[]>([])

  const { statsServiceApi, creativeServiceApi } = useAppContext()

  const { audiences } = orderline

  useEffect(() => {
    const creativePromises =
      (orderline?.creatives &&
        orderline?.creatives?.map((creative: Creativeservicev1Creative) => {
          if (creative.id && creativeServiceApi && orderline.orgId) {
            return creativeServiceApi
              ?.advertisingPlatformServiceGetCreative(
                creative.id,
                orderline.orgId,
              )
              .then((res) => {
                return {
                  creative: res,
                }
              })
              .catch((err) =>
                Promise.resolve({
                  err:
                    err?.body?.message === 'creativeNotFound'
                      ? `Creative not found: ${creative.id}`
                      : `Error getting creative: ${creative.id}. ${
                          err?.body?.message || ''
                        }`,
                }),
              )
          }
          return Promise.resolve({
            err: 'This creative does not have an id.',
            creative,
          })
        })) ||
      []
    if (sortedCreativeStats) {
      const detachCreativeStatSorted = sortedCreativeStats.filter(
        (sortedCreative) => {
          return !orderline?.creatives?.some(
            (attachedCreative) =>
              sortedCreative.creativeId === attachedCreative?.id,
          )
        },
      )
      const detachedPromises = detachCreativeStatSorted.map(
        (detachedCreative: StatsAccumulatorType) => {
          if (
            detachedCreative.creativeId &&
            creativeServiceApi &&
            orderline.orgId
          ) {
            return creativeServiceApi
              ?.advertisingPlatformServiceGetCreative(
                detachedCreative.creativeId,
                orderline.orgId,
              )
              .then((res) => {
                return {
                  creative: res,
                }
              })
              .catch((err) =>
                Promise.resolve({
                  err:
                    err?.body?.message === 'creativeNotFound'
                      ? `Creative not found: ${detachedCreative.creativeId}`
                      : `Error getting creative: ${
                          detachedCreative.creativeId
                        }. ${err?.body?.message || ''}`,
                }),
              )
          }
          return Promise.resolve({
            err: 'This creative does not have an id.',
            detachedCreative,
          })
        },
      )
      creativePromises.push.apply(creativePromises, detachedPromises)
    }

    Promise.all(creativePromises).then((res: CreativeResType[]) => {
      setCreatives(res)
    })
  }, [orderline, sortedCreativeStats])

  useEffect(() => {
    const getOLStats = async () => {
      if (
        !statsServiceApi ||
        olStatsData?.details?.ids?.[0]?.orderLineId === orderline.id
      ) {
        return
      }
      try {
        const start = new Date(orderline?.startTime || 0)
        const end = new Date(orderline?.endTime || 0)
        if (!orderline || !orderline.orgId) return

        // Fetch OL stats
        const olStatsRes = await getOrderLineStats(
          orderline,
          orderline.orgId,
          'day',
        )

        // Update state with OL stats
        setOlStatsData(
          process.env.REACT_APP_ENV === 'production' &&
            !/dev/.test(window.location.hostname)
            ? olStatsRes
            : statMakerForEnvironment(orderline),
        )

        // Fetch creative stats
        const creativeStatsRes = await statsServiceApi?.advertisingPlatformServiceGetStats(
          [orderline.orgId],
          {
            startTime: dayjs.tz(start).startOf('day').utc().toISOString(),
            endTime: dayjs
              .tz(end)
              .endOf('day')
              .add(1, 'day')
              .utc()
              .toISOString(),
            timeFrame: 'total',
            levelOfDetail: ['creative_id'],
            orderLineId: [orderline?.id || ' '],
          },
        )

        // Update state with creative stats
        if (creativeStatsRes !== undefined) {
          setCreativeStatsData(
            process.env.REACT_APP_ENV === 'production' &&
              !/dev/.test(window.location.hostname)
              ? creativeStatsRes
              : creativeStatMaker(orderline),
          )
        }
      } catch (error) {
        console.error('Error fetching stats:', error)
      } finally {
        setLoad(false)
      }
    }
    getOLStats()
  }, [])

  useEffect(() => {
    if (audiences && orderline?.orgId) {
      const counter = async () => {
        if (!orderline?.orgId) return
        const includedAudiences = audiences.filter(
          (currAud) => !currAud.exclude,
        )
        const excludedAudiences = audiences.filter((currAud) => currAud.exclude)
        const excludedJobsQuoted = await getJobQuotes(
          excludedAudiences,
          orderline.orgId,
        )
        const includedJobsQuoted = await getJobQuotes(
          includedAudiences,
          orderline.orgId,
        )
        const allIncludedQuoted = includedJobsQuoted?.quoteStatus?.every(
          (status) => status.quoted === true,
        )
        const allExcludedQuoted = excludedJobsQuoted?.quoteStatus?.every(
          (status) => status.quoted === true,
        )
        if (allIncludedQuoted) {
          setTotalIncludedAudiences(includedJobsQuoted?.count || 0)
          if (excludedAudiences.length > 0 && allExcludedQuoted) {
            setTotalExcludedAudiences(excludedJobsQuoted?.count)
          }
          if (excludedAudiences.length === 0) {
            setTotalExcludedAudiences(undefined)
          }
        } else setTotalIncludedAudiences(0)
      }
      counter()
    }
  }, [audiences, orderline?.id, orderline.orgId])

  useEffect(() => {
    const currentSorted = creativeStatsData?.results
      ?.reduce((acc: StatsAccumulatorType[], val: V1Results) => {
        if (!val.results) return []
        const imps = Number(val?.results[0]?.imps)
        const clicks = Number(val?.results[0]?.clicks)
        const ctr = imps && clicks ? Number((clicks / imps) * 100) : 0
        return [
          ...acc,
          {
            creativeId: val?.details?.ids?.creativeId || '',
            clicks: val?.results[0]?.clicks || 0,
            imps: val?.results[0]?.imps || 0,
            ctr: ctr || 0,
          },
        ]
      }, [])
      .sort((a, b) => {
        if (b.ctr !== undefined && a.ctr !== undefined) {
          return b.ctr - a.ctr
        }
        return 0
      })
    if (currentSorted) {
      setSortedCreativeStats(currentSorted)
    }
  }, [creativeStatsData, creatives])

  const HighestPerformingCreative = () => {
    if (sortedCreativeStats) {
      const attachCreativeStatSorted = sortedCreativeStats.filter(
        (sortedCreative) => {
          return creatives.some(
            (attachedCreative) =>
              sortedCreative.creativeId === attachedCreative.creative?.id,
          )
        },
      )
      const highestCTRId = attachCreativeStatSorted[0]?.creativeId
      const highestCTRCreative = creatives?.filter((currentCreative) => {
        return currentCreative.creative?.id === highestCTRId
      })
      return highestCTRCreative
    }
    return []
  }

  return (
    <div className="ReportModal__container relative">
      <Modal
        classNameContent="ReportModal ReportModal__modal-content relative"
        classNameWrap="ReportModal__modal-wrap"
        className="ReportModal__modal-container"
        offClick={() => closeModal()}
      >
        {/* Leaving in case we want to go back to the original stats PDF */}
        {/* <div className="ReportModal__content-wrap--pdf isPDF absolute z-0">
          <ExportRenderer
            exportAsPDF
            shouldDownload={downloadPDF}
            setShouldDownload={setDownloadPDF}
            setLoading={setLoad}
            fileName={fileName}
            // fileType="pdf"
            loading={load}
            height={1320}
            width={1020}
            headerContent={
              <figure className="ETLogo__wrap flex h-auto w-32 items-center justify-start">
                <img
                  className="h-auto w-full object-contain"
                  src={reportsLogo}
                  alt="logo"
                  width="128"
                  height="44"
                />
              </figure>
            }
          >
            <div className="ReportModal__content-wrap--pdf flex flex-col gap-1">
              <Stats
                olStatsData={olStatsData}
                creativeStatsData={creativeStatsData}
                sortedCreativeStats={sortedCreativeStats}
                className="ReportModal__report--pdf"
                orderline={orderline}
                showAsPDF={true}
                week={week}
                setWeek={setWeek}
                item={item}
                setItem={setItem}
                creatives={creatives}
                highestPerformingCreative={HighestPerformingCreative()?.[0]}
              />
              <div className="Report__CampaignCreatives isPDF flex">
                <div className="gallery-CampaignCreatives HighestPerformingCreative--content isPDF">
                  <Text className="HighestPerformingCreative--header header-CampaignCreatives isPDF">
                    Highest Performing Creative
                  </Text>
                  {HighestPerformingCreative()?.map((x, i) => {
                    const { creative } = x
                    if (!creative)
                      return <Fragment key={`HighestPerfCreative_${i}`} />
                    const { files } = creative
                    const imageFile = files ? files[0] : undefined
                    const { width, height, name, uri } = imageFile || {}

                    const sorter = sortedCreativeStats.filter(
                      (c) => c.creativeId === creative.id,
                    )
                    const imp = sorter[0].imps
                    const click = sorter[0].clicks
                    const ctrs = sorter[0].ctr?.toFixed(2)
                    const PreviewGenerator = () => {
                      if (
                        creative.type === 'CREATIVE_TYPE_BANNER' ||
                        creative.type === 'CREATIVE_TYPE_NATIVE_BANNER'
                      ) {
                        return (
                          <img
                            src={`${uri}?random=${random}`}
                            alt={name}
                            height={height}
                            width={width}
                            crossOrigin="anonymous"
                          />
                        )
                      }
                      if (creative.type === 'CREATIVE_TYPE_AD_TAG') {
                        return (
                          <div className="Banner-thumb flex flex-col">
                            <ETIcon className="scale-125" icon="ad-tag" />
                          </div>
                        )
                      }
                      if (creative.type === 'CREATIVE_TYPE_VAST_TAG') {
                        return (
                          <div className="Banner-thumb flex flex-col">
                            <ETIcon className="scale-125" icon="vast-tag" />
                          </div>
                        )
                      }
                      if (
                        creative.type === 'CREATIVE_TYPE_VIDEO' ||
                        creative.type === 'CREATIVE_TYPE_NATIVE_VIDEO' ||
                        creative.type === 'CREATIVE_TYPE_OTT'
                      ) {
                        return (
                          <div className="Video-thumb flex flex-col">
                            <Fa
                              className="Video-thumb-icon"
                              icon="play"
                              size={3}
                            />
                          </div>
                        )
                      }
                      return (
                        <ETIcon icon="campaign" className="!text-primary" />
                      )
                    }
                    return (
                      <div
                        className="HighestPerformingCreative--item isPDF flex flex-col gap-2"
                        key={creative.id}
                      >
                        <figure
                          className={`HighestPerformingCreative Creative__${name}`}
                        >
                          <span className="block pb-4 text-xs">
                            Name: {name}
                          </span>
                          {PreviewGenerator()}
                        </figure>
                        <figcaption className="pl-1">
                          <ul className="basicData-info">
                            <li>
                              <strong>Imps:</strong> {imp}
                            </li>
                            <li>
                              <strong>Clicks:</strong> {click}
                            </li>
                            <li>
                              <strong>CTR:</strong> {ctrs}
                            </li>
                          </ul>
                        </figcaption>
                      </div>
                    )
                  })}
                </div>
                <div className="CampaignCreatives--content isPDF flex flex-col">
                  <Text className="header-CampaignCreatives isPDF">
                    CREATIVES
                  </Text>
                  <div className="gallery-CampaignCreatives Creatives--content isPDF">
                    {creatives?.map((x, i) => {
                      const { creative } = x
                      if (!creative)
                        return <Fragment key={x.creative?.id || i} />
                      const { files } = creative
                      const imageFile = files ? files[0] : undefined
                      const { width, height, name, uri } = imageFile || {}
                      const { results } = creativeStatsData
                      const singleCreativeStats = results?.filter(
                        (currentRes) => {
                          return (
                            currentRes.details?.ids?.creativeId === creative.id
                          )
                        },
                      )
                      const PreviewGenerator = () => {
                        if (
                          creative.type === 'CREATIVE_TYPE_BANNER' ||
                          creative.type === 'CREATIVE_TYPE_NATIVE_BANNER'
                        ) {
                          return (
                            <img
                              src={`${uri}?random=${random}`}
                              alt={name}
                              height={height}
                              width={width}
                              crossOrigin="anonymous"
                            />
                          )
                        }
                        if (creative.type === 'CREATIVE_TYPE_AD_TAG') {
                          return (
                            <div className="Banner-thumb flex flex-col">
                              <ETIcon className="scale-125" icon="ad-tag" />
                            </div>
                          )
                        }
                        if (creative.type === 'CREATIVE_TYPE_VAST_TAG') {
                          return (
                            <div className="Banner-thumb flex flex-col">
                              <ETIcon className="scale-125" icon="vast-tag" />
                            </div>
                          )
                        }
                        if (
                          creative.type === 'CREATIVE_TYPE_VIDEO' ||
                          creative.type === 'CREATIVE_TYPE_NATIVE_VIDEO' ||
                          creative.type === 'CREATIVE_TYPE_OTT'
                        ) {
                          return (
                            <div className="Video-thumb flex flex-col">
                              <Fa
                                className="Video-thumb-icon"
                                icon="play"
                                size={3}
                              />
                            </div>
                          )
                        }
                        return (
                          <ETIcon icon="campaign" className="!text-primary" />
                        )
                      }
                      const creativeName =
                        files && files?.length > 0
                          ? breakTextWithDelimiters(name as string, 26)
                          : breakTextWithDelimiters(creative?.name || '', 26)
                      return (
                        <div
                          className="Creatives isPDF flex flex-col gap-2"
                          key={creative.id}
                        >
                          <figure className={`Creative Creative__${name}`}>
                            {PreviewGenerator()}
                          </figure>
                          <figcaption className="Creative__name isPDF flex flex-col gap-2 py-2">
                            {creativeName}

                            {singleCreativeStats?.map((stat) => {
                              if (!stat.results)
                                return <Fragment key={`stat_${creative.id}`} />
                              const { results } = stat
                              if (
                                results &&
                                (!results[0].imps || !results[0].clicks)
                              )
                                return <Fragment key={`stat_${creative.id}`} />
                              return (
                                <ul
                                  className="Creative__stats isPDF basicData-info"
                                  key={creative.id}
                                >
                                  <li>
                                    <strong>Imps:</strong> {results[0].imps}
                                  </li>
                                  <li>
                                    <strong>Clicks:</strong> {results[0].clicks}
                                  </li>
                                  <li>
                                    <strong>CTR:</strong>{' '}
                                    {Number(
                                      ((results[0].clicks || 0) /
                                        (results[0].imps || 0)) *
                                        100,
                                    ).toFixed(2)}
                                  </li>
                                </ul>
                              )
                            })}
                          </figcaption>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </ExportRenderer>
        </div> */}

        <div className="ReportModal__content-wrap--online relative z-50">
          <div className="ReportModal__headerOptions relative top-0 right-0 z-50 flex items-center justify-end gap-5">
            <Button
              className="DownloadButton"
              disabled={load}
              onClick={() => {
                setLoad(true)
                // setDownloadPDF(true)
                if (!olStatsData) return
                downloadPDFReport(
                  orderline,
                  olStatsData,
                  item,
                  olStatsData.results?.[0].results?.slice(week, week + 7),
                  HighestPerformingCreative()?.[0]?.creative,
                  sortedCreativeStats,
                  creatives,
                ).then(() => setLoad(false))
              }}
            >
              <ActionButton className="ReportModal__Loading-container">
                {load ? (
                  <LoadingIcons />
                ) : (
                  <>
                    <Fa
                      className="ReportModal__loading-icon"
                      icon="file-pdf"
                      size={1}
                    />
                    <span className="ReportModal__loading-label">
                      Download PDF
                    </span>
                  </>
                )}
              </ActionButton>
            </Button>
            <Button
              className="DownloadButton"
              disabled={loadCSV}
              onClick={() => {
                setLoadCSV(true)
                setColumnModal(true)
              }}
            >
              <ActionButton className="ReportModal__Loading-container">
                {loadCSV ? (
                  <LoadingIcons />
                ) : (
                  <>
                    <Fa
                      className="ReportModal__loading-icon"
                      icon="file-csv"
                      size={1}
                    />
                    <span className="ReportModal__loading-label">
                      Download CSV
                    </span>
                  </>
                )}
              </ActionButton>
            </Button>

            <Button
              className="ReportModal__closeContainer flex h-8 w-8 pl-0 pr-0"
              type="button"
              iconOnly={<Fa icon="times" size={1} />}
              onClick={() => closeModal()}
            />
          </div>

          <Stats
            className="ReportModal__report--online"
            olStatsData={olStatsData}
            creativeStatsData={creativeStatsData}
            sortedCreativeStats={sortedCreativeStats}
            orderline={orderline}
            creatives={creatives}
            showAsPDF={false}
            week={week}
            setWeek={setWeek}
            item={item}
            setItem={setItem}
            highestPerformingCreative={HighestPerformingCreative()?.[0]}
          />
        </div>
      </Modal>
      {columnModal && olStatsData && totalAudiences && (
        <CSVColumnPickerModal
          oL={orderline}
          olStats={olStatsData}
          closeModal={() => {
            setColumnModal(false)
            setLoadCSV(false)
          }}
          includedAudiences={totalAudiences}
          excludedAudiences={totalExcluded}
        />
      )}
    </div>
  )
}
