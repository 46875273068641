import {
  Campaignservicev1OrderLine,
  Creativeservicev1Creative,
  Creativeservicev1Type,
  V1ResultMetrics,
  V1StatsResponse,
} from 'next-gen-sdk'
import { PDFDocument, PDFImage, rgb } from 'pdf-lib'
import { dayjs } from 'Tools/dateUtils'
import fontkit from '@pdf-lib/fontkit'
import reportsLogo from 'Assets/logo.png'
import {
  getApiUrl,
  getOrderLineBudget,
  getOrderLineSpent,
  simplifyEnum,
  truncateMiddle,
} from 'Helpers'
import { daysRemaining } from './Stats'
import axios from 'axios'
import { CreativeResType, StatsAccumulatorType } from 'types'

export const downloadPDFReport = async (
  orderLine: Campaignservicev1OrderLine,
  olStatsData: V1StatsResponse,
  dailyBreakdownType: 'clicks' | 'impressions' | 'ctr',
  dailyBreakdown?: V1ResultMetrics[],
  highestPerformingCreative?: Creativeservicev1Creative,
  sortedCreativeStats?: StatsAccumulatorType[],
  creatives?: CreativeResType[],
) => {
  const pdfDoc = await PDFDocument.create()
  const page = pdfDoc.addPage([612, 792])
  const { width, height } = page.getSize()

  // embed fonts
  let fontRegularImport = require('Assets/Fonts/SourceSans3-Regular.ttf')
  const fontRegularBytes = await fetch(fontRegularImport).then((res) =>
    res.arrayBuffer(),
  )
  let fontBoldImport = require('Assets/Fonts/SourceSans3-Bold.ttf')
  const fontBoldBytes = await fetch(fontBoldImport).then((res) =>
    res.arrayBuffer(),
  )
  pdfDoc.registerFontkit(fontkit)
  const fontRegular = await pdfDoc.embedFont(fontRegularBytes)
  const fontBold = await pdfDoc.embedFont(fontBoldBytes)

  const colorBlue = rgb(44 / 255, 120 / 255, 195 / 255)
  const colorGreen = rgb(6 / 255, 134 / 255, 63 / 255)
  const colorPrimary = rgb(194 / 255, 187 / 255, 158 / 255)

  const headerFontSize = 15
  const fontSize = 12
  const fontSizeSmall = 10
  const fontSizeExtraSmall = 9

  page.moveTo(20, height - 50)
  // eltoro logo
  const logoBytes = await fetch(reportsLogo).then((res) => res.arrayBuffer())
  const logoImg = await pdfDoc.embedPng(logoBytes)
  const logoImgDims = logoImg.scale(0.4)
  page.drawImage(logoImg, {
    width: logoImgDims.width,
    height: logoImgDims.height,
  })
  page.moveDown(8)
  page.drawLine({
    start: { x: 20, y: page.getPosition().y },
    end: { x: width - 20, y: page.getPosition().y },
    thickness: 1.5,
    color: colorPrimary,
  })

  page.moveDown(20)
  // order line title
  page.drawText(`Order line report: ${truncateMiddle(orderLine.name, 55)}`, {
    size: headerFontSize,
    maxWidth: width - 80,
    wordBreaks: [' ', '_', '-'],
    font: fontBold,
    lineHeight: 12,
  })

  //   Generated at
  page.drawText(`Today's date: ${dayjs().tz().format('MM-DD-YYYY')}`, {
    size: 12,
    x: width - 160,
    y: height - 45,
    font: fontRegular,
  })

  page.moveDown(40)

  // Start <-> End dates + days remaining
  page.drawRectangle({
    x: 20,
    y: page.getPosition().y,
    width: page.getWidth() - 40,
    height: 26,
    color: colorPrimary,
    opacity: 0.3,
  })
  page.drawText(`${dayjs.tz(orderLine.startTime).format('MM/DD/YY')}`, {
    x: page.getPosition().x + 10,
    y: page.getPosition().y + 9,
    size: fontSize,
    font: fontBold,
  })
  page.drawText(`${dayjs.tz(orderLine.endTime).format('MM/DD/YY')}`, {
    x:
      page.getWidth() -
      (fontBold.widthOfTextAtSize(
        `${dayjs.tz(orderLine.endTime).format('MM/DD/YY')}`,
        fontSize,
      ) +
        30),
    y: page.getPosition().y + 9,
    size: fontSize,
    font: fontBold,
  })
  const getOrderLineRemainingText = () => {
    const daysRemainingCount = daysRemaining(orderLine)
    const { endTime } = orderLine
    if (daysRemainingCount === 0)
      return `Order line ended ${dayjs().tz().diff(endTime, 'day')} day${
        dayjs().tz().diff(endTime, 'day') <= 1 ? '' : 's'
      } ago`
    return `${daysRemainingCount} day${
      daysRemainingCount === 1 ? '' : 's'
    } remaining`
  }
  page.drawText(getOrderLineRemainingText(), {
    x:
      page.getWidth() / 2 -
      fontBold.widthOfTextAtSize(getOrderLineRemainingText(), fontSize) / 2,
    y: page.getPosition().y + 9,
    size: fontSize,
    font: fontBold,
  })

  page.moveDown(29)
  // Status
  page.drawRectangle({
    x: 20,
    y: page.getPosition().y,
    width: page.getWidth() - 40,
    height: 26,
    color: colorPrimary,
    opacity: 0.3,
  })
  const statusText = (() => {
    if (!orderLine.state) return 'No status'
    if (orderLine.state === 'ORDERLINE_STATE_ACTIVE') {
      return 'Deployed'
    }
    return simplifyEnum(2, orderLine.state, true)
  })()
  page.drawText(statusText, {
    x: page.getPosition().x + 25,
    y: page.getPosition().y + 9,
    size: fontSize,
    font: fontRegular,
  })

  const getStatusIcon = () => {
    switch (orderLine.state) {
      case 'ORDERLINE_STATE_APPROVED':
        return 'M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM625 177L497 305c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L591 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z'
      case 'ORDERLINE_STATE_REJECTED':
        return 'M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z'
      case 'ORDERLINE_STATE_ACTIVE':
        return 'M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9l0 176c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z'
      case 'ORDERLINE_STATE_COMPLETED':
        return 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z'
      case 'ORDERLINE_STATE_ARCHIVED':
        return 'M32 32l448 0c17.7 0 32 14.3 32 32l0 32c0 17.7-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96L0 64C0 46.3 14.3 32 32 32zm0 128l448 0 0 256c0 35.3-28.7 64-64 64L96 480c-35.3 0-64-28.7-64-64l0-256zm128 80c0 8.8 7.2 16 16 16l160 0c8.8 0 16-7.2 16-16s-7.2-16-16-16l-160 0c-8.8 0-16 7.2-16 16z'
      case 'ORDERLINE_STATE_PAUSED':
        return 'M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM224 192l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32s32 14.3 32 32zm128 0l0 128c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-128c0-17.7 14.3-32 32-32s32 14.3 32 32z'
      default:
        return 'M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160L0 416c0 53 43 96 96 96l256 0c53 0 96-43 96-96l0-96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7-14.3 32-32 32L96 448c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 64z'
    }
  }

  const getStatusIconColor = () => {
    switch (orderLine.state) {
      case 'ORDERLINE_STATE_REJECTED':
        return rgb(233 / 255, 0, 41 / 255)
      case 'ORDERLINE_STATE_ACTIVE':
        return colorBlue
      case 'ORDERLINE_STATE_APPROVED':
      case 'ORDERLINE_STATE_COMPLETED':
        return colorGreen
      default:
        return rgb(255 / 255, 154 / 255, 31 / 255)
    }
  }

  const svgPath = getStatusIcon()
  page.drawSvgPath(svgPath, {
    x: page.getPosition().x + 5,
    y: page.getPosition().y + 20,
    color: getStatusIconColor(),
    scale: 0.03,
  })
  // Clicks, impressions, CTR blocks
  page.moveDown(75)

  const mousePointerSVG =
    'M0 55.2L0 426c0 12.2 9.9 22 22 22c6.3 0 12.4-2.7 16.6-7.5L121.2 346l58.1 116.3c7.9 15.8 27.1 22.2 42.9 14.3s22.2-27.1 14.3-42.9L179.8 320l118.1 0c12.2 0 22.1-9.9 22.1-22.1c0-6.3-2.7-12.3-7.4-16.5L38.6 37.9C34.3 34.1 28.9 32 23.2 32C10.4 32 0 42.4 0 55.2z'

  const houseSVG =
    'M575.8 255.5c0 18-15 32.1-32 32.1l-32 0 .7 160.2c0 2.7-.2 5.4-.5 8.1l0 16.2c0 22.1-17.9 40-40 40l-16 0c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1L416 512l-24 0c-22.1 0-40-17.9-40-40l0-24 0-64c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32l0 64 0 24c0 22.1-17.9 40-40 40l-24 0-31.9 0c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2l-16 0c-22.1 0-40-17.9-40-40l0-112c0-.9 0-1.9 .1-2.8l0-69.7-32 0c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z'

  const statsSVG =
    'M160 80c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 352c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-352zM0 272c0-26.5 21.5-48 48-48l32 0c26.5 0 48 21.5 48 48l0 160c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48L0 272zM368 96l32 0c26.5 0 48 21.5 48 48l0 288c0 26.5-21.5 48-48 48l-32 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48z'

  const statRectangleProps = {
    height: 60,
    width: page.getWidth() / 3 - 40,
    borderWidth: 1,
    borderColor: colorPrimary,
  }
  const statIconProps = {
    color: rgb(196 / 255, 33 / 255, 39 / 255),
    scale: 0.08,
  }
  const statFontBold = {
    size: 25,
    font: fontBold,
  }
  const statFontRegular = {
    size: fontSize,
    font: fontRegular,
  }
  const statFontSubBold = {
    size: fontSize,
    font: fontBold,
  }

  // Statboxes
  const { results, totals } = olStatsData
  const resultsBreakdown = results ? results[0] : undefined
  const currentImps = () => Number(resultsBreakdown?.totals?.imps) || 0
  const currentClicks = () => Number(resultsBreakdown?.totals?.clicks) || 0
  const getCTR = () => {
    const ctr = (currentClicks() / currentImps()) * 100
    return Number.isNaN(ctr) || ctr === Infinity ? 'N/A' : `${ctr.toFixed(3)}%`
  }

  // Click statbox
  page.drawRectangle({
    x: 20,
    y: page.getPosition().y,
    ...statRectangleProps,
  })
  page.drawSvgPath(mousePointerSVG, {
    x: page.getPosition().x + 30,
    y: page.getPosition().y + 48,
    ...statIconProps,
  })
  page.drawText(
    `${
      currentClicks() >= 0 ? currentClicks()?.toString() || '' : 'not available'
    }`,
    {
      x: page.getPosition().x + 65,
      y: page.getPosition().y + 27,
      ...statFontBold,
    },
  )
  page.drawText('Clicks', {
    x: page.getPosition().x + 65,
    y: page.getPosition().y + 13,
    ...statFontRegular,
  })

  // Impressions statbox
  page.drawRectangle({
    x: page.getPosition().x + page.getWidth() / 3,
    y: page.getPosition().y,
    ...statRectangleProps,
  })
  page.drawSvgPath(houseSVG, {
    x: page.getWidth() / 2 - 65,
    y: page.getPosition().y + 52,
    ...statIconProps,
  })
  page.drawText(
    `${currentImps() >= 0 ? currentImps()?.toString() || '' : 'not available'}`,
    {
      x: page.getWidth() / 2 - 17,
      y: page.getPosition().y + 27,
      ...statFontBold,
      size: 22,
    },
  )
  page.drawText('Impressions', {
    x: page.getWidth() / 2 - 17,
    y: page.getPosition().y + 13,
    ...statFontRegular,
  })

  // CTR statbox
  page.drawRectangle({
    x: page.getWidth() - 20 - statRectangleProps.width,
    y: page.getPosition().y,
    ...statRectangleProps,
  })
  page.drawSvgPath(statsSVG, {
    x: page.getWidth() - statRectangleProps.width,
    y: page.getPosition().y + 52,
    ...statIconProps,
  })
  page.drawText(`${getCTR()}`, {
    x:
      page.getWidth() -
      fontBold.widthOfTextAtSize(getCTR(), statFontBold.size) -
      40,
    y: page.getPosition().y + 27,
    ...statFontBold,
  })
  page.drawText('CTR', {
    x:
      page.getWidth() -
      fontBold.widthOfTextAtSize(getCTR(), statFontBold.size) -
      40,
    y: page.getPosition().y + 13,
    ...statFontRegular,
  })

  page.moveDown(15)
  page.drawLine({
    start: { x: 20, y: page.getPosition().y },
    end: { x: width - 20, y: page.getPosition().y },
    thickness: 1,
    color: colorPrimary,
  })
  // middle line
  page.drawLine({
    start: { x: page.getWidth() / 2, y: page.getPosition().y },
    end: { x: page.getWidth() / 2, y: page.getPosition().y - 126 },
    thickness: 1,
    color: colorPrimary,
  })

  // https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) => {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    }
  }

  const describeArc = (
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number,
  ) => {
    var start = polarToCartesian(x, y, radius, endAngle)
    var end = polarToCartesian(x, y, radius, startAngle)

    var arcSweep = endAngle - startAngle <= 180 ? '0' : '1'

    var d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      arcSweep,
      0,
      end.x,
      end.y,
      'L',
      x,
      y,
      'L',
      start.x,
      start.y,
    ].join(' ')

    return d
  }
  const totalImpressions = orderLine.impressions
  // Impressions pie (left)
  let impressionSlicePercentage = currentImps() / (totalImpressions || 0)
  const olImpText = (totalImpressions || 0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  page.drawText(olImpText, {
    x:
      page.getWidth() / 2 -
      fontBold.widthOfTextAtSize(olImpText, statFontBold.size) -
      180,
    y: page.getPosition().y - 50,
    ...statFontBold,
  })
  const olImpSubText = 'Total impressions'
  page.drawText(olImpSubText, {
    x:
      page.getWidth() / 2 -
      fontBold.widthOfTextAtSize(olImpSubText, statFontSubBold.size) -
      158,
    y: page.getPosition().y - 50 - fontBold.heightAtSize(statFontSubBold.size),
    ...statFontSubBold,
  })
  // --- key
  page.drawCircle({
    x: 58,
    y: page.getPosition().y - 85,
    size: 7,
    color: colorBlue,
  })
  const impsServedTitle = 'Served:'
  page.drawText(impsServedTitle, {
    x: 68,
    y: page.getPosition().y - 87,
    font: fontRegular,
    size: fontSizeSmall,
  })
  page.drawText(`${currentImps()}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), {
    x: 70 + fontRegular.widthOfTextAtSize(impsServedTitle, fontSizeSmall),
    y: page.getPosition().y - 87,
    font: fontBold,
    size: fontSizeSmall,
  })
  page.drawCircle({
    x: 58,
    y: page.getPosition().y - 103,
    size: 7,
    color: colorBlue,
    opacity: 0.2,
  })
  const impsRemainingText = 'Remaining:'
  page.drawText(impsRemainingText, {
    x: 68,
    y: page.getPosition().y - 106,
    font: fontRegular,
    size: fontSizeSmall,
  })
  const impsRemaining = (() => {
    if (totalImpressions && totalImpressions >= 0) {
      const served = totalImpressions - currentImps()
      if (served < 0) return '0'
      return (totalImpressions - currentImps())
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return '0'
    }
  })()
  page.drawText(impsRemaining, {
    x: 70 + fontRegular.widthOfTextAtSize(impsRemainingText, fontSizeSmall),
    y: page.getPosition().y - 106,
    font: fontBold,
    size: fontSizeSmall,
  })
  // --- pie
  page.drawSvgPath(
    describeArc(
      page.getWidth() / 2 - 100, // x
      64, // y
      50, // radius
      0, // start angle
      impressionSlicePercentage >= 1 ? 359.99 : impressionSlicePercentage * 360, // end angle
    ),
    {
      color: colorBlue,
    },
  )
  page.drawSvgPath(
    describeArc(
      page.getWidth() / 2 - 100, // x
      64, // y
      50, // radius
      impressionSlicePercentage >= 1 ? 1 : impressionSlicePercentage * 360, // start angle
      360, // end angle
    ),
    {
      color: colorBlue,
      opacity: 0.2,
    },
  )

  // Budget pie (right)
  const budget = getOrderLineBudget(orderLine)
  const spent = Number(getOrderLineSpent(orderLine, currentImps()))
  const budgetSlicePercentage = spent / Number((budget || 1).toFixed(2))
  const budgetText = `$${budget
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  page.drawText(budgetText, {
    x: page.getWidth() / 2 + 28,
    y: page.getPosition().y - 50,
    ...statFontBold,
  })
  const budgetSubText = 'Total budget'
  page.drawText(budgetSubText, {
    x: page.getWidth() / 2 + 28,
    y: page.getPosition().y - 50 - fontBold.heightAtSize(statFontSubBold.size),
    ...statFontSubBold,
  })

  // --- key
  page.drawCircle({
    x: page.getWidth() / 2 + 38,
    y: page.getPosition().y - 85,
    size: 7,
    color: colorGreen,
  })
  const budgetServedTitle = 'Total spent:'
  page.drawText(budgetServedTitle, {
    x: page.getWidth() / 2 + 48,
    y: page.getPosition().y - 87,
    font: fontRegular,
    size: fontSizeSmall,
  })
  const budgetSpent = `$${spent
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  page.drawText(budgetSpent, {
    x:
      page.getWidth() / 2 +
      50 +
      fontRegular.widthOfTextAtSize(budgetServedTitle, fontSizeSmall),
    y: page.getPosition().y - 87,
    font: fontBold,
    size: fontSizeSmall,
  })
  page.drawCircle({
    x: page.getWidth() / 2 + 38,
    y: page.getPosition().y - 103,
    size: 7,
    color: colorGreen,
    opacity: 0.2,
  })
  const budgetRemainingText = 'Remaining:'
  page.drawText(budgetRemainingText, {
    x: page.getWidth() / 2 + 48,
    y: page.getPosition().y - 106,
    font: fontRegular,
    size: fontSizeSmall,
  })
  const budgetRemaining = `$${(budget - spent).toFixed(2)}`.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  )
  page.drawText(budgetRemaining, {
    x:
      page.getWidth() / 2 +
      50 +
      fontRegular.widthOfTextAtSize(budgetRemainingText, fontSizeSmall),
    y: page.getPosition().y - 106,
    font: fontBold,
    size: fontSizeSmall,
  })

  // --- pie
  page.drawSvgPath(
    describeArc(
      page.getWidth() - 105, // x
      64, // y
      50, // radius
      0, // start angle
      budgetSlicePercentage >= 1 ? 359.99 : budgetSlicePercentage * 360, // end angle
    ),
    {
      color: colorGreen,
    },
  )
  page.drawSvgPath(
    describeArc(
      page.getWidth() - 105, // x
      64, // y
      50, // radius
      budgetSlicePercentage >= 1 ? 1 : budgetSlicePercentage * 360, // start angle
      360, // end angle
    ),
    {
      color: colorGreen,
      opacity: 0.2,
    },
  )
  page.moveDown(126)
  page.drawLine({
    start: { x: 20, y: page.getPosition().y },
    end: { x: width - 20, y: page.getPosition().y },
    thickness: 1,
    color: colorPrimary,
  })
  page.moveDown(20)
  if (dailyBreakdown) {
    const dayOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    page.drawText(`DAILY BREAKDOWN BY ${dailyBreakdownType.toUpperCase()}`, {
      ...statFontSubBold,
    })
    let x = 20
    const rectangleWidth = (page.getWidth() - 67) / 7
    dailyBreakdown.forEach(({ clicks, imps, end }) => {
      const ctr = (Number(clicks) / Number(imps || 0)) * 100
      page.drawRectangle({
        x: x,
        y: page.getPosition().y - 73,
        color: rgb(210 / 255, 210 / 255, 210 / 255),
        height: 60,
        width: rectangleWidth,
      })
      // top left stat
      if (
        dailyBreakdownType === 'clicks' ||
        dailyBreakdownType === 'impressions'
      ) {
        // ctr
        page.drawText(`${!Number.isNaN(ctr) ? ctr.toFixed(3) : 0}%`, {
          x: x + 5,
          y: page.getPosition().y - 24,
          size: fontSizeExtraSmall,
          font: fontBold,
        })
        page.drawText('CTR', {
          x: x + 5,
          y: page.getPosition().y - 34,
          size: fontSizeExtraSmall,
          font: fontRegular,
        })
      } else {
        // clicks
        page.drawText(`${clicks}`, {
          x: x + 5,
          y: page.getPosition().y - 24,
          size: fontSizeExtraSmall,
          font: fontBold,
        })
        page.drawText('clicks', {
          x: x + 5,
          y: page.getPosition().y - 34,
          size: fontSizeExtraSmall,
          font: fontRegular,
        })
      }
      // top right stat
      if (dailyBreakdownType === 'clicks' || dailyBreakdownType === 'ctr') {
        // imps
        page.drawText(`${imps}`, {
          x:
            x +
            rectangleWidth -
            5 -
            fontBold.widthOfTextAtSize(`${imps}`, fontSizeExtraSmall),
          y: page.getPosition().y - 24,
          size: fontSizeExtraSmall,
          font: fontBold,
        })
        page.drawText('imp.', {
          x:
            x +
            rectangleWidth -
            5 -
            fontRegular.widthOfTextAtSize('imp.', fontSizeExtraSmall),
          y: page.getPosition().y - 34,
          size: fontSizeExtraSmall,
          font: fontRegular,
        })
      } else {
        // clicks
        page.drawText(`${clicks}`, {
          x:
            x +
            rectangleWidth -
            5 -
            fontBold.widthOfTextAtSize(`${clicks}`, fontSizeExtraSmall),
          y: page.getPosition().y - 24,
          size: fontSizeExtraSmall,
          font: fontBold,
        })
        page.drawText('clicks', {
          x:
            x +
            rectangleWidth -
            5 -
            fontRegular.widthOfTextAtSize('clicks', fontSizeExtraSmall),
          y: page.getPosition().y - 34,
          size: fontSizeExtraSmall,
          font: fontRegular,
        })
      }
      // featured stat
      const featuredStatNum = (() => {
        if (clicks === undefined || imps === undefined) return 'n/a'
        if (dailyBreakdownType === 'clicks') return `${clicks}`
        if (dailyBreakdownType === 'impressions') return `${imps}`
        return `${!Number.isNaN(ctr) ? ctr.toFixed(3) : 0}%`
      })()
      const featuredStatProps = { font: fontBold, size: 16 }
      page.drawText(featuredStatNum, {
        x:
          x +
          rectangleWidth / 2 -
          featuredStatProps.font.widthOfTextAtSize(
            featuredStatNum,
            featuredStatProps.size,
          ) /
            2,
        y: page.getPosition().y - 55,
        ...featuredStatProps,
      })
      page.drawText(
        dailyBreakdownType === 'ctr'
          ? dailyBreakdownType.toUpperCase()
          : dailyBreakdownType,
        {
          x:
            x +
            rectangleWidth / 2 -
            fontRegular.widthOfTextAtSize(dailyBreakdownType, fontSizeSmall) /
              2,
          y: page.getPosition().y - 65,
          font: fontRegular,
          size: fontSizeSmall,
        },
      )
      // day
      page.drawText(dayOfWeek[dayjs.tz(end).day()], {
        x:
          x +
          rectangleWidth / 2 -
          fontBold.widthOfTextAtSize(dayOfWeek[dayjs.tz(end).day()], fontSize) /
            2,
        y: page.getPosition().y - 85,
        font: fontBold,
        size: fontSize,
      })
      // date
      page.drawText(dayjs.tz(end).format('MM-DD-YY'), {
        x:
          x +
          rectangleWidth / 2 -
          fontRegular.widthOfTextAtSize(
            dayjs.tz(end).format('MM-DD-YY'),
            fontSize,
          ) /
            2,
        y: page.getPosition().y - 100,
        font: fontRegular,
        size: fontSize,
      })
      // move horizontally
      x = x + rectangleWidth + 4
    })
    page.moveDown(112)
    page.drawLine({
      start: { x: 20, y: page.getPosition().y },
      end: { x: width - 20, y: page.getPosition().y },
      thickness: 1,
      color: colorPrimary,
    })
    page.moveDown(20)
  }
  // Creatives
  const getCreativeIcon = (creativeType?: Creativeservicev1Type): string => {
    if (creativeType === 'CREATIVE_TYPE_NATIVE_BANNER')
      return 'M.3 89.5C.1 91.6 0 93.8 0 96L0 224 0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-192 0-128c0-35.3-28.7-64-64-64L64 32c-2.2 0-4.4 .1-6.5 .3c-9.2 .9-17.8 3.8-25.5 8.2C21.8 46.5 13.4 55.1 7.7 65.5c-3.9 7.3-6.5 15.4-7.4 24zM48 224l416 0 0 192c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-192z'
    if (
      creativeType === 'CREATIVE_TYPE_AD_TAG' ||
      creativeType === 'CREATIVE_TYPE_VAST_TAG'
    )
      return 'M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z'
    if (
      creativeType === 'CREATIVE_TYPE_NATIVE_VIDEO' ||
      creativeType === 'CREATIVE_TYPE_VIDEO'
    )
      return 'M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z'
    if (creativeType === 'CREATIVE_TYPE_OTT')
      return 'M64 64l0 288 512 0 0-288L64 64zM0 64C0 28.7 28.7 0 64 0L576 0c35.3 0 64 28.7 64 64l0 288c0 35.3-28.7 64-64 64L64 416c-35.3 0-64-28.7-64-64L0 64zM128 448l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-384 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z'
    if (creativeType === 'CREATIVE_TYPE_AUDIO')
      return 'M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64l0 384c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352 64 352c-35.3 0-64-28.7-64-64l0-64c0-35.3 28.7-64 64-64l67.8 0L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z'
    if (creativeType === 'CREATIVE_TYPE_MAIL_PIECE')
      return 'M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z'
    if (creativeType === 'CREATIVE_TYPE_HTML5')
      return 'M0 32l34.9 395.8L191.5 480l157.6-52.2L384 32H0zm308.2 127.9H124.4l4.1 49.4h175.6l-13.6 148.4-97.9 27v.3h-1.1l-98.7-27.3-6-75.8h47.7L138 320l53.5 14.5 53.7-14.5 6-62.2H84.3L71.5 112.2h241.1l-4.4 47.7z'
    if (creativeType === 'CREATIVE_TYPE_UNSPECIFIED')
      return 'M80 160c0-35.3 28.7-64 64-64l32 0c35.3 0 64 28.7 64 64l0 3.6c0 21.8-11.1 42.1-29.4 53.8l-42.2 27.1c-25.2 16.2-40.4 44.1-40.4 74l0 1.4c0 17.7 14.3 32 32 32s32-14.3 32-32l0-1.4c0-8.2 4.2-15.8 11-20.2l42.2-27.1c36.6-23.6 58.8-64.1 58.8-107.7l0-3.6c0-70.7-57.3-128-128-128l-32 0C73.3 32 16 89.3 16 160c0 17.7 14.3 32 32 32s32-14.3 32-32zm80 320a40 40 0 1 0 0-80 40 40 0 1 0 0 80z'
    // return image icon
    return 'M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z'
  }

  // fetch creative bytes for top 12 creatives
  const fetchedCreatives = await Promise.all(
    (sortedCreativeStats || []).map(async ({ creativeId }) => {
      const creativeStat = (creatives || []).find(
        (c) => c.creative?.id === creativeId,
      )
      const creative = creativeStat?.creative
      const creativeUrl = creative?.files?.[0]?.uri
      const creativeFile = creative?.files?.[0]
      if (
        (creativeFile?.mime === 'image/jpeg' ||
          creativeFile?.mime === 'image/png') &&
        creativeUrl &&
        creative?.id &&
        creative?.orgId &&
        creativeFile?.id
      ) {
        const creativeImageBytes = await axios({
          method: 'GET',
          url: `${getApiUrl()}/v1/creatives:download?creative_id=${
            creative.id
          }&file_id=${creativeFile.id}&org_id=${creative.orgId}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('eltoro_token')}`,
          },
          responseType: 'arraybuffer',
        })
          .then(({ data }) => {
            return data
          })
          .catch(() => undefined)
        // catch and just show a basic img svg
        let creativeImage: PDFImage | null = null
        if (creativeFile.mime === 'image/jpeg') {
          creativeImage = await pdfDoc.embedJpg(creativeImageBytes)
        }
        if (creativeFile.mime === 'image/png') {
          creativeImage = await pdfDoc.embedPng(creativeImageBytes)
        }
        return { creative, creativeImage }
      } else {
        return { creative, creativeImage: null }
      }
    }),
  )

  //  - Highest performing creative (left)
  if (highestPerformingCreative?.id) {
    const highestPerfCreativeStats = sortedCreativeStats?.find(
      (s) => s.creativeId === highestPerformingCreative?.id,
    )
    const highestPerformingCreativeTitle = 'HIGHEST PERFORMING CREATIVE'
    page.drawText(highestPerformingCreativeTitle, {
      maxWidth: 170,
      lineHeight: 9,
      ...statFontSubBold,
    })
    const highPerfCreativeName =
      truncateMiddle(highestPerformingCreative.name, 25) || 'No name'
    page.drawText(highPerfCreativeName, {
      y: page.getPosition().y - fontBold.heightAtSize(fontSize) - 5,
      maxWidth: 170,
      lineHeight: 9,
      wordBreaks: [' ', '_', '-'],
      font: fontRegular,
      size: fontSizeSmall,
    })
    let highPerfImgDims = { height: 100, width: 100 }
    const generateCreativePreview = (creativeType?: Creativeservicev1Type) => {
      const icon = getCreativeIcon(creativeType)
      page.drawSquare({
        size: 100,
        y: page.getPosition().y - highPerfImgDims.height - 35,
        x:
          fontBold.widthOfTextAtSize(highestPerformingCreativeTitle, fontSize) /
            2 -
          highPerfImgDims.width / 2,
        color: colorPrimary,
        opacity: 0.2,
      })
      if (icon) {
        page.drawSvgPath(icon, {
          y: page.getPosition().y - highPerfImgDims.height / 2 - 22,
          x:
            fontBold.widthOfTextAtSize(
              highestPerformingCreativeTitle,
              fontSize,
            ) /
              2 -
            13,
          scale: 0.05,
          color: colorPrimary,
        })
      }
    }

    const [{ creativeImage: highestPerfCreativeImage }] = fetchedCreatives
    highPerfImgDims = highestPerfCreativeImage?.scaleToFit(100, 100) || {
      height: 100,
      width: 100,
    }
    if (highestPerfCreativeImage) {
      page.drawImage(highestPerfCreativeImage, {
        width: highPerfImgDims.width,
        height: highPerfImgDims.height,
        y: page.getPosition().y - highPerfImgDims.height - 35,
        x:
          fontBold.widthOfTextAtSize(highestPerformingCreativeTitle, fontSize) /
            2 -
          highPerfImgDims.width / 2,
      })
    } else {
      generateCreativePreview(highestPerformingCreative.type)
    }

    // vertical divider
    page.drawLine({
      start: { x: 165, y: page.getPosition().y + 20 },
      end: { x: 165, y: 28 },
      thickness: 1,
      color: colorPrimary,
    })
    // highest performing creative stats
    page.drawText('Impressions', {
      font: fontBold,
      size: fontSizeExtraSmall,
      y:
        page.getPosition().y -
        fontBold.heightAtSize(fontSize) -
        fontRegular.heightAtSize(fontSizeSmall) -
        highPerfImgDims.height -
        20,
    })
    const impsText = `${highestPerfCreativeStats?.imps || 0}`
    page.drawText(impsText, {
      font: fontRegular,
      size: fontSizeExtraSmall,
      x: 155 - fontRegular.widthOfTextAtSize(impsText, fontSizeExtraSmall),
      y:
        page.getPosition().y -
        fontBold.heightAtSize(fontSize) -
        fontRegular.heightAtSize(fontSizeSmall) -
        highPerfImgDims.height -
        20,
    })
    const dashProps = {
      thickness: 1,
      color: colorPrimary,
      dashArray: [1],
      dashPhase: 100,
    }
    page.drawLine({
      start: {
        x: 20,
        y: page.getPosition().y - highPerfImgDims.height - 60,
      },
      end: {
        x: 155,
        y: page.getPosition().y - highPerfImgDims.height - 60,
      },
      ...dashProps,
    })
    page.drawText('Clicks', {
      font: fontBold,
      size: fontSizeExtraSmall,
      y:
        page.getPosition().y -
        fontBold.heightAtSize(fontSize) -
        fontRegular.heightAtSize(fontSizeSmall) -
        highPerfImgDims.height -
        41,
    })
    const clicksText = `${highestPerfCreativeStats?.clicks || 0}`
    page.drawText(clicksText, {
      font: fontRegular,
      size: fontSizeExtraSmall,
      x: 155 - fontRegular.widthOfTextAtSize(clicksText, fontSizeExtraSmall),
      y:
        page.getPosition().y -
        fontBold.heightAtSize(fontSize) -
        fontRegular.heightAtSize(fontSizeSmall) -
        highPerfImgDims.height -
        41,
    })
    page.drawLine({
      start: {
        x: 20,
        y: page.getPosition().y - highPerfImgDims.height - 79,
      },
      end: {
        x: 155,
        y: page.getPosition().y - highPerfImgDims.height - 79,
      },
      ...dashProps,
    })
    page.drawText('CTR', {
      font: fontBold,
      size: fontSizeExtraSmall,
      y:
        page.getPosition().y -
        fontBold.heightAtSize(fontSize) -
        fontRegular.heightAtSize(fontSizeSmall) -
        highPerfImgDims.height -
        61,
    })
    const ctrText = `${(highestPerfCreativeStats?.ctr || 0).toFixed(3)}`
    page.drawText(ctrText, {
      font: fontRegular,
      size: fontSizeExtraSmall,
      x: 155 - fontRegular.widthOfTextAtSize(ctrText, fontSizeExtraSmall),
      y:
        page.getPosition().y -
        fontBold.heightAtSize(fontSize) -
        fontRegular.heightAtSize(fontSizeSmall) -
        highPerfImgDims.height -
        61,
    })
  }
  //  - Top 12 creative listing (right)
  const creativesTitle =
    (creatives || []).length > 12 ? 'TOP 12 CREATIVES' : 'CREATIVES'
  // determine start position depending on highest perf creative
  const startingX = highestPerformingCreative ? 175 : page.getPosition().x
  page.drawText(creativesTitle, {
    ...statFontSubBold,
    x: startingX,
  })
  // get creatives in groups of 6
  const creativeGroupArray = fetchedCreatives?.reduce(
    (
      result: Array<
        Array<{
          creative: Creativeservicev1Creative | undefined
          creativeImage: PDFImage | null
        }>
      >,
      _,
      index,
    ) => {
      if (index % 6 === 0) {
        result.push((fetchedCreatives || []).slice(index, index + 6))
      }
      return result
    },
    [],
  )
  const firstPageCreatives = creativeGroupArray?.slice(0, 2)
  let x = startingX
  const creativeSquareWidth = (page.getWidth() - startingX - 25) / 6
  const thumbnailSize = creativeSquareWidth - 30
  let y = page.getPosition().y - creativeSquareWidth - 40
  firstPageCreatives?.forEach((creativeBatch) => {
    creativeBatch.forEach(async ({ creative, creativeImage }) => {
      page.drawRectangle({
        width: creativeSquareWidth,
        height: 100,
        x,
        y,
        color: rgb(240 / 255, 240 / 255, 240 / 255),
      })
      x = x + 70

      let imgDims = {
        height: thumbnailSize,
        width: thumbnailSize,
      }
      const generateCreativePreview = (
        creativeType?: Creativeservicev1Type,
      ) => {
        const icon = getCreativeIcon(creativeType)
        page.drawSquare({
          size: 40,
          color: colorPrimary,
          opacity: 0.2,
          x: x - creativeSquareWidth / 2 - 20,
          y: y + 55,
        })
        if (icon) {
          page.drawSvgPath(icon, {
            scale: 0.05,
            color: colorPrimary,
            x: x - creativeSquareWidth / 2 - 13,
            y: y + 87,
          })
        }
      }
      // pdf-lib can only embed jpeg + png- maybe we can get around this in the future by using ExportRenderer
      if (creativeImage) {
        imgDims = creativeImage?.scaleToFit(thumbnailSize, thumbnailSize) || {
          height: thumbnailSize,
          width: thumbnailSize,
        }
        if (creativeImage) {
          page.drawImage(creativeImage, {
            width: imgDims.width,
            height: imgDims.height,
            x: x - creativeSquareWidth / 2 - imgDims.width / 2,
            y: y + 95 - imgDims.height,
          })
        } else {
          generateCreativePreview(creative?.type)
        }
      } else {
        generateCreativePreview(creative?.type)
      }
      // stats
      const creativeStats = sortedCreativeStats?.find(
        (s) => s.creativeId === creative?.id,
      )
      page.drawText(truncateMiddle(`${creative?.name || 'No name'}`, 20), {
        font: fontBold,
        size: fontSizeExtraSmall,
        x: x - creativeSquareWidth + 5,
        y: y + 42,
        maxWidth: creativeSquareWidth - 4,
        lineHeight: 6,
        wordBreaks: [' ', '_', '-'],
      })
      const impsText = `Imps: ${creativeStats?.imps || 0}`
      page.drawText(impsText, {
        font: fontRegular,
        size: fontSizeExtraSmall,
        x: x - creativeSquareWidth + 5,
        y: y + 25,
      })
      const clicksText = `Clicks: ${creativeStats?.clicks || 0}`
      page.drawText(clicksText, {
        font: fontRegular,
        size: fontSizeExtraSmall,
        x: x - creativeSquareWidth + 5,
        y: y + 25 - 8,
      })
      const ctrText = `CTR: ${(creativeStats?.ctr || 0).toFixed(3)}`
      page.drawText(ctrText, {
        font: fontRegular,
        size: fontSizeExtraSmall,
        x: x - creativeSquareWidth + 5,
        y: y + 25 - 16,
      })
    })
    y = y - 102
    x = startingX // reset next row
  })
  if ((creatives || []).length > 12) {
    const moreCreativesText = `+ ${(creatives || []).length - 12} more`
    page.drawText(moreCreativesText, {
      font: fontBold,
      size: fontSize,
      x:
        page.getWidth() -
        20 -
        fontBold.widthOfTextAtSize(moreCreativesText, fontSize),
      y: 50,
    })
  }

  // downloading
  const pdfBytes = await pdfDoc.save()
  const blob = new Blob([pdfBytes], {
    type: 'application/pdf',
  })
  const url = window.URL.createObjectURL(blob)
  const fileName = `${orderLine.name?.replaceAll(
    '.',
    '_',
  )}_report_${dayjs().tz().format('MM-DD-YYYY')}`
  setTimeout(() => window.URL.revokeObjectURL(url), 1000)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  // a.target = '_blank' // for testing instead of downloading (new tab)
  document.body.appendChild(a)
  a.style.display = 'none'
  a.click()
}
