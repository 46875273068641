import React from 'react'

import { createRoot } from 'react-dom/client'
import { AuthProvider } from 'react-oidc-context'
import {
  localConfig,
  devConfig,
  prodConfig,
  tiltConfig,
  prodNextGenConfig,
} from './AuthConfig'
import './tw.scss'
import { ErrorBoundary } from './Components'
import App from './App'
import { ETIcons } from './Assets'
import { AppContextProvider } from './Contexts'
import reportWebVitals from './reportWebVitals'
import './Assets/FontAwesomePro6/css/all.css'
import { User } from 'oidc-client-ts'

export type AuthClient = {
  idToken?: string
  refreshToken?: string
  token?: string
  access_token?: string
}
const switcher = () => {
  if (process.env.REACT_APP_ENV === 'tilt') {
    return tiltConfig
  }
  if (/localhost/.test(window.location.hostname)) {
    return localConfig
  }
  if (
    process.env.REACT_APP_ENV === 'development' ||
    /dev/.test(window.location.hostname)
  ) {
    return devConfig
  }
  if (process.env.REACT_APP_ENV === 'production') {
    if (/nextgen/.test(window.location.hostname)) {
      return prodNextGenConfig
    } else {
      return prodConfig
    }
  }
  return localConfig
}
const setRedirect = () => {
  if (window.location.pathname !== '/') {
    localStorage.setItem('oidc:redirect', window.location.pathname)
    localStorage.setItem('oidc:query', window.location.search)
  }
}
const container = document.getElementById('root')
const root = container && createRoot(container)
const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState(
    {},
    document.title,
    (localStorage.getItem('oidc:redirect') as string) || '/',
  )
  const search = localStorage.getItem('oidc:query') as string
  if (search) {
    window.location.search = search
  }
  if (/orgs/.test(window.location.href)) {
    window.location.reload()
  }
  localStorage.removeItem('oidc:redirect')
  localStorage.removeItem('oidc:query')
}
const onRemoveUser = (): void => {
  // go to home after logout
  window.history.replaceState({}, document.title, '/')
}
setRedirect()
root?.render(
  /* @ts-ignore */
  <AuthProvider
    // determine which env for auth.
    {...switcher()}
    onSigninCallback={onSigninCallback}
    onRemoveUser={onRemoveUser}
  >
    <React.StrictMode>
      <ErrorBoundary>
        <AppContextProvider>
          <App />
          <ETIcons />
          {/* import all of ET custom icons to index (hidden) so they're rendered once, available globally */}
        </AppContextProvider>
      </ErrorBoundary>
    </React.StrictMode>
  </AuthProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
