import {
  Targetjobservicev1Audience,
  Campaignservicev1Audience,
} from 'next-gen-sdk'
import { ApiFactory } from 'apiFactory'
import { getApiConfig } from 'Requests'
import { getJobCounts } from 'Helpers'

export const checkIfFullJob = (
  item: Campaignservicev1Audience | Targetjobservicev1Audience,
): item is Targetjobservicev1Audience => {
  return !!(item as Targetjobservicev1Audience).result
}

// Takes a list of targets and returns the job matches and wether they are all quoted or not
export const getJobQuotes = async (
  audiences: (Campaignservicev1Audience | Targetjobservicev1Audience)[],
  org: string,
) => {
  if (!audiences) return undefined
  let quoteStatus: {
    job: Targetjobservicev1Audience | Campaignservicev1Audience
    quoted: boolean
    count?: number
  }[] = []
  const token = localStorage.getItem('eltoro_token')
  const { audienceServiceAPI } = await ApiFactory(token)
  if (!audienceServiceAPI) return {}

  const jobCounts = await Promise.all(
    audiences.map((currentJob) => {
      if (!currentJob.id) return 0
      // if the job has matches, don't bother checking quote status
      if (!checkIfFullJob(currentJob) && currentJob.matched) {
        quoteStatus = [...quoteStatus, { job: currentJob, quoted: true }]
        return currentJob.matched
      }
      // if job already has results and use them instead of fetching
      if (checkIfFullJob(currentJob) && currentJob.result) {
        const jobResult = currentJob.result
        if (
          !jobResult ||
          (jobResult?.processCompleted !== undefined &&
            jobResult.processCompleted < 1)
        ) {
          quoteStatus = [...quoteStatus, { job: currentJob, quoted: false }]
        } else {
          quoteStatus = [
            ...quoteStatus,
            {
              job: currentJob,
              quoted: true,
              count: getJobCounts(currentJob),
            },
          ]
        }
        return getJobCounts(currentJob) || 0
      }
      // last resort, fetch the audience
      return audienceServiceAPI
        .advertisingPlatformServiceGetAudience(
          currentJob.id,
          org,
          getApiConfig(localStorage.getItem('eltoro_token') || token || ''),
        )
        .then((fullJob) => {
          const jobResult = fullJob.result
          if (
            fullJob.status !== 'AUDIENCE_STATUS_READY' &&
            fullJob.status !== 'AUDIENCE_STATUS_COMPLETED' &&
            (!jobResult ||
              (jobResult?.processCompleted !== undefined &&
                jobResult.processCompleted < 1))
          ) {
            quoteStatus = [...quoteStatus, { job: fullJob, quoted: false }]
          } else {
            quoteStatus = [
              ...quoteStatus,
              {
                job: fullJob,
                quoted: true,
                count: getJobCounts(fullJob),
              },
            ]
          }

          return getJobCounts(fullJob) || 0
        })
    }),
  )

  return {
    count:
      jobCounts.length > 0 ? jobCounts.reduce((prev, num) => prev + num) : 0,
    quoteStatus,
  }
}
