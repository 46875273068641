import { Fa, Text } from '@eltoro-ui/components'
import { Orgmanagerv1CPM, V1ProductType } from 'next-gen-sdk'
import { CPMObjectType } from 'types'
import {
  getAudienceProductIcon,
  getTypeMoneyCost,
  styleTailwind,
  userFriendlyAudienceName,
} from 'Helpers'
import { PriceTextInput } from 'Components'
import { ETIcon } from 'Assets'

const ColumnLabel = styleTailwind(
  'p',
  'text-grey-600 uppercase text-m font-semibold',
)
const Cell = styleTailwind('div', 'Cell p-2 flex items-center')
const Icon = styleTailwind(Fa, 'text-primary')
const NAText = styleTailwind('p', 'text-grey-200')

const CPMPrice: React.FC<{
  cpmKey: string
  units?: number
  nanos?: number
  id?: string
  onChange?: (newCpm: CPMObjectType) => void
  isPolitical: boolean
  adType: string
  parentCpms?: CPMObjectType
}> = ({
  cpmKey,
  units,
  nanos,
  id,
  onChange,
  isPolitical,
  adType,
  parentCpms,
}) => {
  const keyName = isPolitical
    ? `political ${cpmKey} ${adType}`
    : `${cpmKey} ${adType}`

  const parentCpm =
    parentCpms?.[keyName] && getTypeMoneyCost(parentCpms?.[keyName] || {})

  const value = +(units || 0) + (nanos || 0) / 10 ** 9
  if (!onChange)
    return value ? <Text>${value.toFixed(2)}</Text> : <NAText>n/a</NAText>
  return (
    <>
      <PriceTextInput
        min={parentCpm || 1}
        max={999}
        value={value}
        onChange={(input) => {
          const numberInput = +input
          const newUnits = numberInput - (numberInput % 1)
          const newNanos = Math.round(+(numberInput % 1) * 10 ** 9)
          onChange({
            [keyName]: {
              product_name: keyName,
              currency_code: 'USD',
              units: newUnits.toString(),
              nanos: newNanos,
              id,
            },
          })
        }}
      />
      {parentCpm && (
        <span
          data-tooltip="top"
          aria-label={`Parent CPM: $${parentCpm.toFixed(2)}`}
        >
          <Fa
            icon="tag"
            size={1}
            className="text-grey-400 hover:cursor-pointer focus:ring"
          />
        </span>
      )}
    </>
  )
}

const CPMRow: React.FC<{
  cpm: {
    name: string
    video?: Orgmanagerv1CPM
    banner?: Orgmanagerv1CPM
    OTT?: Orgmanagerv1CPM
  }
  onChange?: (newCpm: CPMObjectType) => void
  isPolitical: boolean
  parentCpms?: CPMObjectType
}> = ({ cpm, onChange, isPolitical, parentCpms }) => {
  const priceProps = { onChange, isPolitical, parentCpms }
  const icon = cpm.name
    ? getAudienceProductIcon(
        `PRODUCT_TYPE_${cpm.name.toUpperCase()}` as V1ProductType,
      )
    : null

  return (
    <>
      <Cell className="justify-start">
        <div className="w-10 px-2">{icon && <ETIcon icon={icon} />}</div>
        {cpm.name &&
          userFriendlyAudienceName(`PRODUCT_TYPE_${cpm.name.toUpperCase()}`)}
        {cpm.name === 'ip_targeting' && (
          <span
            data-tooltip="top"
            aria-label="This includes B2C, B2B, ZIP Code List, IP Address List"
          >
            <Fa
              icon="info-circle"
              size={1}
              className="text-grey-400 hover:cursor-pointer focus:ring"
            />
          </span>
        )}
      </Cell>
      <Cell className="justify-center">
        {cpm.banner ? (
          <CPMPrice
            cpmKey={cpm.name}
            units={Number(cpm.banner?.units || 0)}
            nanos={cpm.banner?.nanos || 0}
            adType="banner"
            id={cpm.banner.id}
            {...priceProps}
          />
        ) : (
          <NAText>n/a</NAText>
        )}
      </Cell>
      <Cell className="justify-center">
        {cpm.video ? (
          <CPMPrice
            cpmKey={cpm.name}
            units={Number(cpm.video?.units || 0)}
            nanos={cpm.video?.nanos || 0}
            adType="video"
            id={cpm.video.id}
            {...priceProps}
          />
        ) : (
          <NAText>n/a</NAText>
        )}
      </Cell>
      <Cell className="justify-center">
        {cpm.OTT ? (
          <CPMPrice
            cpmKey={cpm.name}
            units={Number(cpm.OTT?.units || 0)}
            nanos={cpm.OTT?.nanos || 0}
            adType="ott"
            id={cpm.OTT.id}
            {...priceProps}
          />
        ) : (
          <NAText>n/a</NAText>
        )}
      </Cell>
    </>
  )
}

export const CPMTable: React.FC<{
  isPolitical: boolean
  parentCpms?: CPMObjectType // used by child orgs modal to compare new to old
  inputCpms?: CPMObjectType
  onChange?: (cpm: CPMObjectType) => void
}> = ({ isPolitical, parentCpms, inputCpms, onChange }) => {
  const cpmArray = Object.entries(inputCpms || {})

  const getCPMSbyCreativeType = (cpms: Array<[string, Orgmanagerv1CPM]>) => {
    const products = [
      ...new Set(
        cpms.map((cpm) => {
          const split = cpm[0].split(' ')
          return split[split.length - 2]
        }),
      ),
    ]
    const byCreativeType = products.map((product) => {
      const relatedCPMs = cpms.filter((cpm) => cpm[0]?.includes(product))
      return {
        name: product,
        video: relatedCPMs.find((cpm) => {
          return cpm[0]?.includes('video') && !cpm[0]?.includes('native_video')
        })?.[1],
        banner: relatedCPMs.find((cpm) => cpm[0]?.includes('banner'))?.[1],
        OTT: relatedCPMs.find((cpm) => cpm[0]?.includes('ott'))?.[1],
      }
    })
    return byCreativeType
  }

  const currentCPMRows = getCPMSbyCreativeType(cpmArray)

  return (
    <>
      <div className="CPM__table pb-2">
        <div className="zebra-striped grid auto-rows-fr grid-cols-4">
          <div className="row-start-1 row-end-1" />
          <div className="col-start-2 col-end-5 flex items-center justify-center">
            <ColumnLabel>Creative Categories</ColumnLabel>
          </div>
          {/* Header columns */}
          <div className="flex items-center justify-start py-2 pr-2 pl-4">
            <ColumnLabel>Products</ColumnLabel>
          </div>
          <Cell className="justify-center gap-1">
            <Icon icon="image" size={1} />
            <span>Banner</span>
            <span
              data-tooltip="top"
              aria-label="This includes Banner, HTML5, Native Ad and Ad Tags"
            >
              <Fa
                icon="info-circle"
                size={1}
                className="text-grey-400 hover:cursor-pointer focus:ring"
              />
            </span>
          </Cell>
          <Cell className="justify-center gap-1">
            <Icon icon="video" size={1} />
            <span>Video</span>
            <span
              data-tooltip="top"
              aria-label="This includes Video and VAST Tags"
            >
              <Fa
                icon="info-circle"
                size={1}
                className="text-grey-400 hover:cursor-pointer focus:ring"
              />
            </span>
          </Cell>
          <Cell className="justify-center gap-1">
            <Icon icon="tv" size={1} />
            <span>OTT</span>
          </Cell>
          {/* CPM Rows */}
          {currentCPMRows.map((cpm, i) => {
            return (
              <CPMRow
                key={`${cpm.name} ${i}`}
                cpm={cpm}
                onChange={onChange}
                isPolitical={isPolitical}
                parentCpms={parentCpms}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}
