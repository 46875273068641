import React, { useState, useContext, useEffect, useRef } from 'react'
import { AppContextType, TOSStatusType } from 'types'
import { ApiFactory } from 'apiFactory'
import {
  Orgmanagerv1Org,
  WebToHomeApi,
  NewMoverApi,
  OrganizationsApi,
  TargetsApi,
  NotificationsApi,
  CampaignsApi,
  CreativesApi,
  StatsApi,
  BillingApi,
  AudiencesApi,
  GlobalSearchApi,
  UsersApi,
  TermsOfServiceApi,
  Billingservicev1Org,
} from 'next-gen-sdk'
import { SearchType } from 'Components/MainHeader/components'
import { showErrorMessage } from '@eltoro-ui/components'
import { useMemo } from 'react'
import { useAuth } from 'react-oidc-context'

export type AuthClient = {
  idToken?: string
  refreshToken?: string
  token?: string
}

export const appContextBaseState = {
  currentOrg: undefined,
  setCurrentOrg: () => {},
  refreshCurrentOrg: () => {},
  rebuild: false,
  setRebuild: () => {},
  tok: '',
  setTok: () => {},
  roles: [],
  setRoles: () => {},
  setGroups: () => {},
  searchValue: '',
  setSearchValue: () => {},
  searchType: 'all' as SearchType,
  setSearchType: () => {},
  errorRebuildCount: React.createRef() as React.MutableRefObject<number>,
  isOnHold: false,
  setCurrentBillingOrg: () => {},
  tosStatus: 'loading' as TOSStatusType,
  setTosStatus: () => {},
  overrideCreditCardRequirement: false,
}

export const AppContext = React.createContext<AppContextType>(
  appContextBaseState,
)

// Export a hook to use context (avoids importing useContext+AppContext every time)
export const useAppContext = () => {
  const ctx = useContext(AppContext)
  if (!ctx)
    throw new Error('useAppContext must be used within the AppContextProvider')
  return ctx
}

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentOrg, setCurrentOrg] = useState<Orgmanagerv1Org>()
  // prettier-ignore
  const [currentBillingOrg, setCurrentBillingOrg] = useState<Billingservicev1Org>()
  const [orgServiceApi, setOrgServiceApi] = useState<OrganizationsApi>()
  const [targetServiceApi, setTargetServiceApi] = useState<TargetsApi>()
  const [notificationsApi, setNotificationsApi] = useState<NotificationsApi>()
  // prettier-ignore
  const [campaignServiceApi, setCampaignServiceApi] = useState<
    CampaignsApi
  >()
  // prettier-ignore
  const [creativeServiceApi, setCreativeServiceApi] = useState<
    CreativesApi
  >()
  // prettier-ignore
  const [statsServiceApi, setStatsServiceApi] = useState<StatsApi>()
  const [webToHomeApi, setWebToHomeApi] = useState<WebToHomeApi>()
  // prettier-ignore
  const [billingServiceApi, setBillingServiceApi] = useState<
    BillingApi
  >()
  // prettier-ignore
  const [audienceServiceApi, setAudienceServiceApi] = useState<
    AudiencesApi
  >()

  const [newMoverApi, setNewMoverApi] = useState<NewMoverApi>()
  const [searchServiceApi, setSearchServiceApi] = useState<GlobalSearchApi>()
  const [userServiceApi, setUserServiceApi] = useState<UsersApi>()
  const [tosApi, setTosApi] = useState<TermsOfServiceApi>()
  const [tok, setTok] = useState<string>('')
  const isMounted = useRef(false)

  // Temp Admin Hack
  // const [admin, setAdmin] = useState<boolean>(false)
  const [rebuild, setRebuild] = useState<boolean>(true)
  const [roles, setRoles] = useState<string[]>()
  const [groups, setGroups] = useState<string[]>()
  // global search menu state
  const [searchValue, setSearchValue] = useState('')
  const [searchType, setSearchType] = useState<SearchType>('all')
  const [orgError, setOrgError] = useState(false)
  const auth = useAuth()
  const isAdmin = roles && roles.includes('nextgen_admin')
  const isUser = roles && roles.includes('nextgen_user')
  const isReadOnly = roles && roles.includes('nextgen_read_only')
  const isExternalSales = groups?.includes('/Sales/External')
  const isInternalSales = groups?.includes('/Sales/Internal')
  const isLeadershipOrDev =
    groups?.includes('/Operations/Leadership') || groups?.includes('/Dev')
  const [
    overrideCreditCardRequirement,
    setOverrideCreditCardRequirement,
  ] = useState(false)

  const determineOverrideCreditCardRequirement = async () => {
    if (currentOrg && currentOrg.billableOrgId && orgServiceApi) {
      await orgServiceApi
        .advertisingPlatformServiceGetOrg(currentOrg.billableOrgId)
        .then((billableOrg: any) => {
          setOverrideCreditCardRequirement(
            billableOrg.overrideCreditCardRequirement,
          )
        })
        .catch(() => {
          setOverrideCreditCardRequirement(
            currentOrg.overrideCreditCardRequirement || false,
          )
        })
    } else {
      setOverrideCreditCardRequirement(
        currentOrg && currentOrg.overrideCreditCardRequirement
          ? currentOrg.overrideCreditCardRequirement
          : false,
      )
    }
  }
  const isOnHold =
    isUser &&
    !isExternalSales &&
    !isInternalSales &&
    (currentOrg?.accountingHold ||
      currentOrg?.adminAccountingHold ||
      currentOrg?.billingHold ||
      (!currentOrg?.setupFeePaid && !currentOrg?.billableOrgId))
  const userId = auth?.user?.profile?.sub
  const [tosStatus, setTosStatus] = useState<TOSStatusType>(
    !isAdmin ? 'loading' : 'signed',
  )
  const [orgId, setOrgId] = useState('')

  const query = useMemo(() => new URLSearchParams(window.location.search), [])

  const URLOrg = query.get('org_id')

  useEffect(() => {
    if (rebuild && auth.user?.access_token) {
      const apis = ApiFactory(auth.user?.access_token)
      setOrgServiceApi(apis.orgServiceAPI)
      setTargetServiceApi(apis.targetServiceAPI)
      setCreativeServiceApi(apis.creativeServiceAPI)
      setNotificationsApi(apis.notificationsAPI)
      setCampaignServiceApi(apis.campaignServiceAPI)
      setStatsServiceApi(apis.statsServiceAPI)
      setBillingServiceApi(apis.billingServiceAPI)
      setWebToHomeApi(apis.webToHomeAPI)
      setNewMoverApi(apis.newMoverAPI)
      setSearchServiceApi(apis.searchServiceAPI)
      setUserServiceApi(apis.userServiceAPI)
      setAudienceServiceApi(apis.audienceServiceAPI)
      setTosApi(apis.tosApi)
      setTok(auth.user.access_token)
      localStorage.setItem('eltoro_token', auth.user.access_token)
      setRebuild(false)
    }
  }, [rebuild, auth.user?.access_token])

  useEffect(() => {
    // unable to throw this error within the below useEffect, so throwing it here for the error boundary to catch
    if (orgError) throw new Error('Fetching orgs error')
  }, [orgError])

  useEffect(() => {
    let ignore = false
    const fetchOrgsAndSetFirst = async () => {
      if (!orgServiceApi) return
      // for testing on localhost
      // if (isAdmin && /localhost/.test(window.location.hostname)) {
      //   // return the UAT Team 3 org
      //   const defaultElToroOrg = await orgServiceApi.advertisingPlatformServiceGetOrg(
      //     '8268dc71-8298-4582-b2ff-5688c2863e0d',
      //   )
      //   if (defaultElToroOrg) setCurrentOrg(defaultElToroOrg)
      //   return
      // }
      if (
        isAdmin &&
        (process.env.REACT_APP_ENV === 'production' ||
          /nextgen/.test(window.location.hostname))
      ) {
        // return the ET Pizza org
        const defaultElToroOrg = await orgServiceApi.advertisingPlatformServiceGetOrg(
          '586fc97d1829051225a4662d',
        )
        if (defaultElToroOrg) setCurrentOrg(defaultElToroOrg)
        return
      }
      // get first org in list
      const res = await orgServiceApi
        .advertisingPlatformServiceListOrgs(
          1, // pageSize
          undefined, // pageToken
          'create_time desc', // orderBy
          undefined, // filter
          true, // skipPreload
        )
        .catch(() => {
          setOrgError(true)
          return undefined
        })

      if (res?.orgs && !currentOrg && !ignore) {
        const [firstOrg] = res.orgs
        if (!firstOrg?.id) return
        const fullFirstOrg = await orgServiceApi.advertisingPlatformServiceGetOrg(
          firstOrg.id,
        )
        setCurrentOrg(fullFirstOrg)
      }
    }

    const handleGetInitialOrg = async () => {
      if (!orgServiceApi || orgError) return
      let urlOrgId = ''
      if (
        window.location.pathname.includes('org-settings') ||
        window.location.pathname.includes('child-orgs') ||
        window.location.pathname.includes('billing')
      ) {
        const orgIdFromParams = window.location.pathname
          .split('/')
          .reduce((acc, str) => {
            if (
              str === 'orgs' ||
              !str ||
              str === 'org-settings' ||
              str === 'child-orgs' ||
              str === 'billing'
            )
              return acc
            return str
          }, '')

        if (orgIdFromParams) {
          urlOrgId = orgIdFromParams
        }
      } else if (URLOrg) {
        urlOrgId = URLOrg
      }

      if (urlOrgId) {
        setOrgId(urlOrgId)
        const fetchedOrg = await orgServiceApi
          .advertisingPlatformServiceGetOrg(urlOrgId)
          .catch((err) => {
            if (err?.body?.message === 'orgNotFound') {
              showErrorMessage(
                'Org Not Found',
                `The organization was not found. Org ID: ${urlOrgId}`,
              )
            } else {
              showErrorMessage(
                `Error getting org ${urlOrgId}`,
                err?.body?.message || '',
              )
            }
            fetchOrgsAndSetFirst()
          })
        if (fetchedOrg && !ignore) {
          setCurrentOrg(fetchedOrg)
        }
      } else {
        fetchOrgsAndSetFirst()
      }
    }

    if (!currentOrg && orgServiceApi && isMounted.current) {
      handleGetInitialOrg()
    } else {
      isMounted.current = true
    }

    return () => {
      ignore = true
    }
  }, [currentOrg, orgServiceApi, URLOrg, query, orgError])

  useEffect(() => {
    // if current org id changes, update the routes
    if (currentOrg?.id) {
      const orgId = currentOrg.id
      const query = new URLSearchParams(window.location.search)
      query.delete('org_id')
      query.append('org_id', orgId)
      // if org-settings
      if (window.location.pathname.includes('org-settings')) {
        query.delete('org_id')
        window.history.replaceState(
          '',
          '',
          `${window.location.origin}/orgs/${orgId}/org-settings?${query}`,
        )
        return
      }
      // if child-orgs
      if (window.location.pathname.includes('child-orgs')) {
        query.delete('org_id')
        window.history.replaceState(
          '',
          '',
          `${window.location.origin}/orgs/${orgId}/child-orgs?${query}`,
        )
        return
      }
      // if billing
      if (window.location.pathname.includes('billing')) {
        query.delete('org_id')
        window.history.replaceState(
          '',
          '',
          `${window.location.origin}/orgs/${orgId}/billing?${query}`,
        )
        return
      }
      // everything else, update org_id
      window.history.replaceState(
        '',
        '',
        `${window.location.pathname}${query ? `?${query}` : ''}`,
      )
    }
  }, [currentOrg?.id])

  // check for billing needs
  useEffect(() => {
    let ignore = false
    determineOverrideCreditCardRequirement()
    if (currentOrg?.id && billingServiceApi && !currentOrg.billableOrgId) {
      billingServiceApi
        .advertisingPlatformServiceGetBillingOrg(currentOrg.id)
        .then((billingOrg) => {
          if (ignore) return
          setCurrentBillingOrg(billingOrg)
        })
    }
    return () => {
      ignore = true
    }
  }, [billingServiceApi, currentOrg, isOnHold])

  // check for tos
  useEffect(() => {
    let ignore = false
    if (
      isAdmin ||
      isInternalSales ||
      isExternalSales ||
      currentOrg?.msaSigned
    ) {
      setTosStatus('signed')
      return
    }
    if (currentOrg?.id && userId) {
      setTosStatus('loading')
      setTimeout(() => {
        const { tosApi } = ApiFactory(auth.user?.access_token)

        tosApi
          ?.advertisingPlatformServiceListTOSAgreements(currentOrg.id, userId)
          .then((res) => {
            if ((res.tosAgreements && res.tosAgreements.length) || !ignore) {
              setTosStatus('signed')
              return
            }
            setTosStatus('not-signed')
          })
          .catch((err) => {
            if (
              err.body?.message === 'could not find tos agreements' &&
              !ignore
            )
              setTosStatus('not-signed')
          })
      }, 1000)
    }
    return () => {
      ignore = true
    }
  }, [currentOrg, isAdmin, isExternalSales, isInternalSales, userId])

  // will refresh current org
  const refreshCurrentOrg = () => {
    if (!orgServiceApi || !currentOrg?.id) return
    orgServiceApi
      .advertisingPlatformServiceGetOrg(currentOrg.id)
      .then((res) => setCurrentOrg(res))
  }

  // Counting how many times the user rebuilds when encountering an error message (preventing rebuild loop when the 401 is not resolved)
  // This is reset to 0 in AuthorizedRoutes when the route changes. (Cannot access router here)
  const errorRebuildCount = useRef<number>(0)

  const contextValues = {
    currentOrg,
    setCurrentOrg,
    refreshCurrentOrg,
    audienceServiceApi,
    orgServiceApi,
    targetServiceApi,
    creativeServiceApi,
    notificationsApi,
    campaignServiceApi,
    statsServiceApi,
    billingServiceApi,
    webToHomeApi,
    newMoverApi,
    searchServiceApi,
    userServiceApi,
    tosApi,
    setRebuild,
    rebuild,
    errorRebuildCount,
    tok,
    setTok,
    roles,
    setRoles,
    groups,
    setGroups,
    isAdmin,
    isExternalSales,
    isInternalSales,
    isUser,
    isReadOnly,
    isLeadershipOrDev,
    isOnHold,
    searchValue,
    setSearchValue,
    searchType,
    setSearchType,
    currentBillingOrg,
    setCurrentBillingOrg,
    tosStatus,
    setTosStatus,
    overrideCreditCardRequirement,
  }
  if (!children) {
    return <></>
  }
  return (
    <AppContext.Provider value={contextValues}>{children}</AppContext.Provider>
  )
}
