import { Orgmanagerv1Org } from 'next-gen-sdk'

// Polling function - T is the return type
export const poll = async <T extends unknown>(
  fn: (currentToken?: string) => Promise<T | undefined>, // async func to repeat
  fnCondition: (res?: T) => boolean, // if true, keep polling
  ms: number, // how often to poll (ms)
  timeout?: number, // stop after this amount of time (ms)
) => {
  const firstToken = localStorage.getItem('eltoro_token') || undefined
  let result = await fn(firstToken)
  const end = timeout ? Date.now() + timeout : null
  while (fnCondition(result)) {
    if (end && Date.now() >= end) {
      break
    }
    await wait(ms)
    try {
      const token = localStorage.getItem('eltoro_token') || undefined
      result = await fn(token)
    } catch {
      break
    }
  }
  return result
}

const wait = function (ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

// Creates the "org_id =" portion of a filter string
export const getOrgFilter = (
  childOrgsOnly: boolean,
  currentOrg?: Orgmanagerv1Org,
  childOrgFilter?: string[],
) => {
  if (!currentOrg?.id) return undefined
  if (childOrgFilter && childOrgFilter.length > 0) {
    return `org_id = ${childOrgFilter
      .map((child) => `"${child}"` || '')
      .join(' OR org_id = ')}`
  }
  const filters =
    childOrgsOnly && currentOrg?.childOrgs
      ? [currentOrg, ...currentOrg.childOrgs].map(
          (child) => `"${child.id}"` || '',
        )
      : [`"${currentOrg.id}"`]
  return `org_id = ${filters.join(' OR org_id = ')}`
}

// Gives the api url depending on environment
export const getApiUrl = () => {
  const env = process.env.REACT_APP_ENV
  if (env === 'tilt') {
    return 'http://localhost:8000'
  }
  return process.env.REACT_APP_ENV === 'production' &&
    !/dev/.test(window.location.hostname)
    ? 'https://hagrid.api.eltoro.com'
    : 'https://hagrid.api.dev.eltoro.com'
}

// Converts object's keys from camelCase to snake_case
export const convertObjectKeysToSnakeCase = (obj: { [key: string]: any }) => {
  const newObject: { [key: string]: any } = {}
  Object.keys(obj).forEach((key) => {
    newObject[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = obj[key]
  })
  return newObject
}
