import { useContext, useEffect, useRef, useState } from 'react'
import {
  Header,
  Button,
  Avatar,
  Popover,
  HeaderDropdown,
  HeaderItem,
  Fa,
  Modal,
} from '@eltoro-ui/components'
import {
  useDelayUnmount,
  useMediaQuery,
  useOnClickOutside,
} from '@eltoro-ui/hooks'
import classNames from 'classnames'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { clearSession, DebugButtons } from 'Tools'
import {
  useCampaignContext,
  ThemeContext,
  useAppContext,
  useNotificationContext,
} from 'Contexts'
import {
  ShoppingCart,
  HeaderLogo,
  HeaderNotification,
  SupportRequestModal,
} from 'Components'
import { useGlobalSearchQuery, useMouseHandlers } from 'Hooks'
import {
  AdvancedSearchModal,
  MainHeaderSearchMenu,
  SearchType,
} from './components'
import './MainHeader.scss'
import { useAuth } from 'react-oidc-context'
import axios from 'axios'

export const MainHeader = ({ disableSearch }: { disableSearch?: boolean }) => {
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false)
  const [localSearchType, setLocalSearchType] = useState<SearchType>('all')
  const [localSearchValue, setLocalSearchValue] = useState<string>('')
  const [localMenu, setLocalMenu] = useState<boolean>(false)
  const [mobileMenu, setMobileMenu] = useState<boolean>(false)
  const [mobileSearch, setMobileSearch] = useState<boolean>(false)
  const { name, handleThemeChange } = useContext(ThemeContext)
  const auth = useAuth()
  const { logoThumb } = useContext(ThemeContext)
  const { campaign, newCartChange } = useCampaignContext()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const {
    setRoles,
    setSearchType,
    setSearchValue,
    roles,
    setGroups,
  } = useAppContext()
  const { newNotifications } = useNotificationContext()
  const { setRebuild } = useAppContext()

  const timeOutId = useRef<number | null>(1000)
  const { handleMouseEnter, handleMouseLeave } = useMouseHandlers({
    onMouseEnter: () => setShowAvatarDropdown(true),
    onMouseLeave: () => setShowAvatarDropdown(false),
    delay: timeOutId,
  })

  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const [isTablet] = useMediaQuery('(max-width: 1023px)')
  const [isNotMobile] = useMediaQuery('(min-width: 641px)')
  const [darkMode] = useMediaQuery('(prefers-color-scheme: dark)')

  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false)
  const [shoppingCartOpen, setShoppingCartOpen] = useState(false)
  const [notification, setNotification] = useState(false)
  const [supportModal, setSupportModal] = useState(false)
  const shouldRenderShoppingCart = useDelayUnmount(shoppingCartOpen, 500)
  const avatarRef = useRef<HTMLButtonElement>(null)
  const avatarDropdownRef = useRef<HTMLDivElement>(null)
  const mobileNavBtnRef = useRef<HTMLButtonElement>(null)
  const mobileNavDropdownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside([avatarRef, avatarDropdownRef], () =>
    setShowAvatarDropdown(false),
  )
  useOnClickOutside([mobileNavBtnRef, mobileNavDropdownRef], () =>
    setMobileMenu(false),
  )

  useEffect(() => {
    const parseJwt = (token: string) => {
      try {
        return JSON.parse(atob(token.split('.')[1]))
      } catch (e) {
        return null
      }
    }
    if (auth.user?.access_token) {
      const userRoles = parseJwt(auth.user?.access_token)
      if (userRoles) {
        if (userRoles.realm_access) {
          setRoles(userRoles.realm_access.roles)
          setGroups(userRoles.groups)
        }
      }
    }
  }, [auth, setGroups, setRoles])
  useEffect(() => {
    if (!pathname.includes('/search')) {
      setLocalSearchValue('')
      setLocalSearchType('all')
    }
  }, [pathname])

  const searchMenuProps = {
    localMenu,
    setLocalMenu,
    localSearchType,
    setLocalSearchType,
    setShowAdvancedSearch,
    disabled: disableSearch,
  }
  const getPlaceholderText = () => {
    if (localSearchType === 'all')
      return 'Search for audiences, creatives, orgs, or order lines'
    if (localSearchType === 'audiences') return 'Search for audiences'
    if (localSearchType === 'creatives') return 'Search for creatives'
    if (localSearchType === 'order lines') return 'Search for order lines'
    if (localSearchType === 'orgs') return 'Search for orgs'
    return ''
  }

  const query = useGlobalSearchQuery()

  const handleSubmitSearch = () => {
    setSearchType(localSearchType)
    setSearchValue(localSearchValue)
    const newQuery = new URLSearchParams()
    // 1. on search page, has query in URL already
    if (pathname.includes('/search')) {
      // current query includes creatives, audiences, orgs, order lines
      const currentInclude = query.getAll('include')
      currentInclude.forEach((inc) => newQuery.append('include', inc))
    } else {
      // 2. not on search page, would need to include all or [searchThing]
      if (localSearchType === 'all') {
        const allInclusions = ['audiences', 'orgs', 'creatives', 'order lines']
        allInclusions.forEach((inc) => newQuery.append('include', inc))
      }
      if (localSearchType === 'audiences')
        newQuery.append('include', 'audiences')
      if (localSearchType === 'creatives')
        newQuery.append('include', 'creatives')
      if (localSearchType === 'order lines')
        newQuery.append('include', 'order lines')
      if (localSearchType === 'orgs') newQuery.append('include', 'orgs')
    }

    // set new search value
    newQuery.set('keyword', localSearchValue)
    navigate(`/search?${newQuery}`)
  }

  const SearchComponent = () => {
    return (
      <div className="MainHeader__search-container">
        <div
          className={`TextInput w-full flex-1${
            disableSearch ? ' opacity-30' : ''
          }`}
        >
          <MainHeaderSearchMenu {...searchMenuProps} />
          <input
            value={localSearchValue}
            onChange={(e) => setLocalSearchValue(e.target.value)}
            placeholder={getPlaceholderText()}
            className="ml-[0.75rem] w-full border-none focus:outline-none focus:placeholder:opacity-0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmitSearch()
              }
            }}
            disabled={disableSearch}
          />
          {localSearchValue ? (
            <button
              type="button"
              className="bg-tint-khaki-200 hover:bg-tint-khaki-400 animate-slideright flex h-full items-center gap-0 pr-2 pl-1"
              onClick={!disableSearch ? handleSubmitSearch : () => {}}
              disabled={disableSearch}
            >
              <Fa icon="search" size={1} />
              <p className="text-m font-bold">Search</p>
            </button>
          ) : (
            <div className="bg-tint-khaki-200 animate-fadein flex h-full items-center px-2">
              <Fa icon="search" size={1} />
            </div>
          )}
        </div>
      </div>
    )
  }

  useEffect(() => {
    const keyword = query.get('keyword')
    if (keyword) setLocalSearchValue(keyword)
    const includes = query.getAll('include')
    if (includes.length === 1) {
      if (includes[0] === 'audiences') setLocalSearchType('audiences')
      if (includes[0] === 'orgs') setLocalSearchType('orgs')
      if (includes[0] === 'order lines') setLocalSearchType('order lines')
      if (includes[0] === 'creatives') setLocalSearchType('creatives')
      return
    }
    setLocalSearchType('all')
  }, [query])
  return (
    <>
      <Header
        id="MainHeader"
        className={`MainHeader ${
          isMobile
            ? 'MainHeaderMobile'
            : isTablet
            ? 'MainHeaderTablet'
            : 'MainHeaderDesktop'
        }`}
      >
        <div className="MainHeader__branding flex-1">
          <HeaderLogo logo={logoThumb} to="/" />
        </div>
        <div className="MainHeader__search">
          {!isMobile ? (
            <>{SearchComponent()}</>
          ) : (
            <>
              <Button
                className={`SearchButton ${
                  isMobile
                    ? 'isMobile'
                    : isTablet
                    ? 'isTablet'
                    : 'isDesktop hidden'
                } ${mobileMenu ? 'mobileNavOpen' : 'mobileNavClosed'}`}
                iconOnly={
                  <Fa className="SearchButton__icon" icon="search" size={1} />
                }
                onClick={() => setMobileSearch(!mobileSearch)}
                data-tooltip="left"
                aria-label="Click to search"
              />
            </>
          )}
        </div>
        <div className="MainHeader__nav">
          <Popover
            align="start"
            position={['bottom']}
            isOpen={showAvatarDropdown}
            content={
              <div
                className="MainHeader__dropdown"
                ref={avatarDropdownRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to={
                    process.env.NODE_ENV === 'development'
                      ? `https://auth.api.dev.eltoro.com/auth/realms/eltoro/account?referrer=eltoro-ui`
                      : `https://auth.api.eltoro.com/auth/realms/eltoro/account?referrer=eltoro-ui`
                  }
                  className="Button Button--m-size Button Button--text Button Button--has-left-icon pl-0 font-medium"
                >
                  <span className="Button__icon-wrapper">
                    <Fa icon="user" size={1} />
                  </span>
                  <span className="Button__text">My Profile</span>
                </Link>
                <Button
                  kind="text"
                  iconLeft={
                    <Fa
                      icon={
                        name === 'dark' || (darkMode && name === 'default')
                          ? 'sun-bright'
                          : 'moon'
                      }
                      size={1}
                    />
                  }
                  onClick={() => {
                    if (name === 'dark' || name === 'default')
                      handleThemeChange('light')
                    if (name === 'light' || name === 'default')
                      handleThemeChange('dark')
                  }}
                  className="pl-0"
                >
                  {`Change to ${
                    name === 'dark' || (darkMode && name === 'default')
                      ? 'Light'
                      : 'Dark Mode'
                  }`}
                </Button>
                {name !== 'default' ? (
                  <Button
                    kind="text"
                    iconLeft={
                      <Fa
                        icon={
                          name === 'dark' || darkMode
                            ? 'circle-half-stroke'
                            : 'circle'
                        }
                        size={1}
                      />
                    }
                    onClick={() => {
                      handleThemeChange('default')
                      setRebuild(true)
                    }}
                    className="pl-0"
                  >
                    Revert to Default
                  </Button>
                ) : undefined}
                <Button
                  kind="text"
                  iconLeft={<Fa icon="sign-out-alt" size={1} />}
                  onClick={() => {
                    const params = new URLSearchParams()
                    const config = {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                    }
                    if (!auth.user?.refresh_token) return
                    params.append('client_id', 'eltoro-ui')
                    params.append('refresh_token', auth.user?.refresh_token)
                    axios
                      .post(
                        `https://auth.api.${
                          !/dev/.test(window.location.hostname) &&
                          process.env.REACT_APP_ENV === 'production'
                            ? ''
                            : 'dev.'
                        }eltoro.com/auth/realms/eltoro/protocol/openid-connect/logout`,
                        params,
                        config,
                      )
                      .then((res) => {
                        if (res) {
                          auth.removeUser()
                          auth.signoutSilent()
                          clearSession()
                          navigate('../logout')
                        }
                      })
                  }}
                  className="pl-0"
                >
                  Logout
                </Button>
              </div>
            }
          >
            <div className="MainHeader__avatar">
              <div className="MainHeader__avatar-wrap">
                <Avatar
                  onClick={() => setShowAvatarDropdown(!showAvatarDropdown)}
                  avatarRef={avatarRef}
                  name={auth.user?.profile.preferred_username}
                  src=""
                  rounded
                  size="s"
                />
                <span className="MainHeader__avatar-name">
                  {auth.user?.profile?.preferred_username}
                </span>
              </div>
            </div>
          </Popover>
          {isMobile ? (
            <button
              type="button"
              className={`HamburgerMenu__wrap ${
                isMobile
                  ? 'isMobile'
                  : isTablet
                  ? 'isTablet'
                  : 'isDesktop hidden'
              } ${mobileMenu ? 'mobileNavOpen' : 'mobileNavClosed'}`}
              onClick={() => setMobileMenu(!mobileMenu)}
              ref={mobileNavBtnRef}
              data-tooltip="left"
              aria-label="Click for more items"
            >
              <span className="HamburgerMenu">
                <span className="bar" />
              </span>
            </button>
          ) : null}
          {(mobileMenu || isNotMobile) && (
            <div
              className="MainHeader__nav-items-wrap"
              ref={mobileNavDropdownRef}
            >
              <div
                className={`MainHeader__nav-items ${
                  isMobile ? 'isMobile' : isTablet ? 'isTablet' : 'isDesktop'
                }`}
              >
                <HeaderDropdown
                  position="left"
                  className="HeaderNotification__wrap relative"
                  ariaLabel={`${
                    newNotifications.length > 0 ? newNotifications.length : 'No'
                  } message${newNotifications.length > 1 ? 's' : ''}`}
                  icon={
                    <>
                      {newNotifications.length > 0 && (
                        <span className="AlertNumberBadge bg-danger-600 absolute flex items-center justify-center rounded-full">
                          <span className="text-tint-light text-xs font-bold">
                            {newNotifications.length}
                          </span>
                        </span>
                      )}
                      <Fa icon="bell" size={2} />
                    </>
                  }
                  hasDropdownIcon={false}
                >
                  <HeaderItem onClick={() => setNotification(!notification)}>
                    <HeaderNotification />
                  </HeaderItem>
                </HeaderDropdown>

                <HeaderDropdown
                  position="left"
                  icon={<Fa icon="question-circle" size={2} />}
                  hasDropdownIcon={false}
                  ariaLabel="Need help?"
                >
                  <HeaderItem onClick={() => setSupportModal(true)}>
                    <div className="flex gap-1">
                      <Fa icon="envelope" size={1} />
                      Submit Request
                    </div>
                  </HeaderItem>
                  <HeaderItem to="/documentation">
                    <div className="flex min-w-[200px] gap-1">
                      <Fa icon="sticky-note" size={1} />
                      API Documentation
                    </div>
                  </HeaderItem>
                  <HeaderItem to="https://support.eltoro.com/hc/en-us">
                    <div className="flex gap-1">
                      <Fa icon="books" size={1} />
                      Knowledge Base
                    </div>
                  </HeaderItem>
                </HeaderDropdown>
                {roles && roles.includes('nextgen_admin') && <DebugButtons />}
                <HeaderItem
                  className="ShoppingCart__header-item desktop:pr-6 tabletlarge:pr-6 tabletsmall:pr-3 m-0 py-3 pl-3"
                  onClick={() => setShoppingCartOpen(!shoppingCartOpen)}
                  data-tooltip="left"
                  aria-label={`${
                    campaign?.id ? 'Click to view cart' : 'Empty cart'
                  }`}
                >
                  <div className="MainHeader__cart-icon-container relative">
                    {campaign?.id && (
                      <Fa
                        icon="dot-circle"
                        size={1}
                        className={classNames(
                          'MainHeader__cart-icon-indicator text-danger-600 desktop:-right-2.5 desktopsmall:-right-2.5 mobile:right-1 desktop:-top-3 mobile:-top-2 desktopsmall:-top-3 absolute',
                          {
                            'text-danger-400 animate-ping': newCartChange,
                          },
                        )}
                      />
                    )}
                    <Fa
                      className={classNames('MainHeader__cart-icon', {
                        'animate-bounce': newCartChange,
                      })}
                      icon="shopping-cart"
                      size={2}
                    />
                  </div>
                </HeaderItem>
              </div>
            </div>
          )}
        </div>
      </Header>
      {shouldRenderShoppingCart && (
        <Modal
          className={classNames(
            'MainHeader__shopping-cart-container bg-base fixed top-2 right-3 max-h-[calc(100vh_-_5rem)] min-w-[22rem] max-w-[40rem] pb-8 pt-4',
            {
              'MainHeader__shopping-cart-container--hidden': !shoppingCartOpen,
            },
          )}
          noStyles
          offClick={() => setShoppingCartOpen(false)}
        >
          <ShoppingCart />
        </Modal>
      )}
      {showAdvancedSearch && (
        <AdvancedSearchModal setShowAdvancedSearch={setShowAdvancedSearch} />
      )}
      {supportModal && (
        <SupportRequestModal handleClose={() => setSupportModal(false)} />
      )}
      {mobileSearch ? (
        <Modal
          attachTo={document.getElementById('MainAppContainer') ?? undefined}
          className="MainHeader__search-mobile mx-2 w-full !px-4 !py-4"
          classNameContent="MainHeader__search-mobile-content max-w-full p-0"
          classNameWrap="MainHeader__search-mobile-wrap w-full items-start mt-16"
          noStyles
          offClick={() => setMobileSearch(false)}
        >
          {SearchComponent()}
        </Modal>
      ) : null}
    </>
  )
}
