import { useState } from 'react'
import {
  Button,
  Fa,
  FormItem,
  Modal,
  PreviewType,
  TextBody,
  TextHeader,
  Uploader,
  showSuccessMessage,
} from '@eltoro-ui/components'
import {
  Campaignservicev1Campaign,
  Campaignservicev1OrderLine,
  Orgmanagerv1Org,
} from 'next-gen-sdk'
import classNames from 'classnames'
import { useAuth } from 'react-oidc-context'
import { v4 as uuid } from 'uuid'
import { useAppContext } from 'Contexts'
import {
  ErrorMessage,
  getEmailTemplate,
  isValidCharLength,
  simplifyEnum,
  validCheck,
} from 'Helpers'
import { supportRequest } from 'Requests'
import { SupportRequestUploadList } from './SupportRequestUploadList'
export type PreviewWithId = {
  id: string
  file: PreviewType
}
// If an orderLine is provided, the details will be included
export const SupportRequestModal = ({
  orderLine,
  campaign,
  handleClose,
}: {
  orderLine?: Campaignservicev1OrderLine
  campaign?: Campaignservicev1Campaign
  handleClose: () => void
}) => {
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState<PreviewWithId[]>([])
  const [uploadMsgs, setUploadMsgs] = useState<
    {
      msg: string
      type: 'error' | 'warning'
      fileName: string
    }[]
  >()
  const [sendingRequest, setSendingRequest] = useState(false)
  const [errorSubmitting, setErrorSubmitting] = useState(false)
  const { notificationsApi, orgServiceApi, currentOrg } = useAppContext()
  const auth = useAuth()

  const handleSubmit = async () => {
    if (!currentOrg?.id || !notificationsApi) return
    setSendingRequest(true)
    try {
      let parentOrg: Orgmanagerv1Org | undefined = undefined
      if (currentOrg?.parentOrgId && orgServiceApi) {
        parentOrg = await orgServiceApi
          .advertisingPlatformServiceGetOrg(currentOrg.parentOrgId)
          // return undefined if user does not have access to parent org
          .catch(() => undefined)
      }

      let filesBody: string = ''

      const url =
        process.env.NODE_ENV === 'production' &&
        !/dev/.test(window.location.hostname)
          ? 'https://nextgen.eltoro.com/'
          : 'https://eltoro-ui.api.dev.eltoro.com/'
      const urlBS =
        process.env.NODE_ENV === 'production' &&
        !/dev/.test(window.location.hostname)
          ? 'https://eltoro-backstage.api.eltoro.com'
          : 'https://eltoro-backstage.api.dev.eltoro.com'

      const orderLineBody =
        orderLine &&
        `<p>
          <b>Order Line Details:</b>
        </p>
        <table
          style="
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            border: 1px solid #bbbbbb;
          "
        >
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Name</strong>:
              <a
                href="${url}campaigns/dashboard/order-lines/${
          orderLine.id
        }?org_id=${orderLine.orgId}"
                style="color: #124e8a; text-decoration: underline"
                >${orderLine.name}</a
              >
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
          <td style="padding: 8px; border: 1px solid #bbbbbb">
            <strong>Admin Portal:</strong>:
            <a
              href="${urlBS}/order-line-management/org/${
          orderLine.orgId
        }/orderLine/${orderLine.id}?org_id=${orderLine.orgId}"
              style="color: #124e8a; text-decoration: underline"
              >${orderLine.name}</a
            >
          </td>
        </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>ID</strong>: ${orderLine.id}
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Campaign</strong>: ${
                orderLine.campaign?.id
                  ? `<a href="${url}campaigns/dashboard/${
                      orderLine.campaign?.id
                    }?org_id=${orderLine.orgId}">${
                      orderLine.campaign?.name || 'No name'
                    }</a>`
                  : 'No campaign'
              }
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Status</strong>: ${
                orderLine.status ? simplifyEnum(2, orderLine.status) : 'Unknown'
              }
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Start</strong>: ${orderLine.startTime}
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Stop</strong>: ${orderLine.endTime}
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Impressions</strong>: ${orderLine.impressions}
            </td>
          </tr>
        </table>`

      const campaignBody =
        campaign &&
        !orderLine &&
        `<p>
          <b>Campaign Details:</b>
        </p>
        <table
          style="
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            border: 1px solid #bbbbbb;
          "
        >
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Name</strong>:
              <a
                href="${url}campaigns/dashboard/${campaign.id}/?org_id=${campaign.orgId}"
                style="color: #124e8a; text-decoration: underline"
                >${campaign.name}</a
              >
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>ID</strong>: ${campaign.id}
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Start</strong>: ${campaign.startTime}
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
            <td style="padding: 8px; border: 1px solid #bbbbbb">
              <strong>Stop</strong>: ${campaign.endTime}
            </td>
          </tr>
          <tr style="border: 1px solid #bbbbbb">
          <td style="padding: 8px; border: 1px solid #bbbbbb">
            <strong>Admin Portal</strong>: <a href="${urlBS}">${urlBS}</a>
          </td>
        </tr>
        </table>`

      const emailBody = `
      <p>
        <b>Requested By:</b> ${auth.user?.profile.name} <br />
        <b>Email: </b> <a
        href="mailto:${auth.user?.profile.email}"
        style="color: #124e8a; text-decoration: underline"
        >(${auth.user?.profile.email})</a>
        <br />
        <b>Org:</b> <a href="${url}orgs/${currentOrg?.id}/org-settings">${
        currentOrg?.name || 'No name'
      }</a>  <br />
      ${
        currentOrg?.parentOrgId && parentOrg?.name
          ? `
      <b>Parent Org:</b> <a href="${url}orgs/${parentOrg?.id}/org-settings">${parentOrg?.name}</a>  <br />`
          : ''
      }
      </p>
      <p>
        <b>Customer Notes:</b>
        <br />
        ${description}
      </p>
      ${orderLineBody || ''}
      ${campaignBody || ''}
      ${filesBody}
      `

      const getTitle = () => {
        if (orderLine)
          return `Order Line Support Req: ${auth.user?.profile.name || ''} - ${
            orderLine.name
          }`
        if (campaign?.name)
          return `Campaign Support Req: ${auth.user?.profile.name || ''} - ${
            campaign.name
          }`
        return `Support Request from ${auth.user?.profile.name || ''}`
      }

      await supportRequest(
        getEmailTemplate('Support Request', emailBody),
        getTitle(),
        currentOrg.id,
        'general',
        files,
      )
        .then(() => {
          showSuccessMessage(
            'Your support request has been sent.',
            'Responses to your request will be sent via email.',
          )
          handleClose()
        })
        .catch((err) => {
          const parsedErr = JSON.parse(err.message || '{}')
          if (
            parsedErr.status &&
            parsedErr.message &&
            parsedErr.status === 504 &&
            parsedErr.message === 'upstream request timeout'
          ) {
            handleClose()
            return
          }
          throw new Error(err)
        })
    } catch {
      setErrorSubmitting(true)
    } finally {
      setSendingRequest(false)
    }
  }

  const handleCheckFile = (file: PreviewType): PreviewWithId | undefined => {
    const id = uuid()
    if (
      [
        'application/zip',
        'application/x-gzip',
        'application/x-zip-compressed',
        'application/x-compressed',
        'zip',
        'text/csv',
        'video/avi',
        'video/mp4',
        'video/mpeg',
        'video/ogv',
        'video/ts',
        'video/webm',
        'video/3gp',
        'video/3g2',
        'video/mov',
        'image/jpeg',
        'image/gif',
        'image/png',
      ].includes(file.type)
    ) {
      return {
        id,
        file,
      }
    }

    setUploadMsgs((prev) => {
      return [
        ...(prev || []),
        {
          fileName: file.name,
          msg: 'is not a compatible image, video, or zip file.',
          type: 'error',
        },
      ]
    })
  }

  return (
    <Modal
      noStyles
      offClick={handleClose}
      className="SupportRequestModal tablet:min-w-[calc(100%_-_2rem)] tablet:mt-[unset] max-h-[100vh] min-w-[50%]"
      header={<TextHeader className="py-1">Support request</TextHeader>}
      classNameContent="border-t border-grey-50 flex-col gap-2 flex"
    >
      {sendingRequest && (
        <div className="OLSuppotRequestModal__sending bg-base absolute top-0 bottom-0 right-0 left-0 z-40 overflow-clip p-2 opacity-60">
          <Fa
            className="text-tint-khaki"
            icon="spinner"
            size={6}
            animate="spin"
          />
        </div>
      )}
      <TextBody type={1}>
        {orderLine
          ? 'Need to make modifications or ask a question about your order line? '
          : 'Need to ask a question? '}
        Fill out the form below to submit a request to our AdOps team.
      </TextBody>
      <TextBody type={1}>
        Please provide details on what you need help with.
      </TextBody>
      <FormItem
        htmlFor="description"
        label="Description"
        wrapperClassname="flex-col"
        errorMessage={ErrorMessage({
          fieldName: description,
          max: 255,
          label: 'Description',
          isValid:
            validCheck(description) && isValidCharLength(description, 255),
        })}
        valid={validCheck(description) && isValidCharLength(description, 255)}
        counter={description.length > 0}
      >
        <textarea
          className="TextInput TextInput__input border-grey-50 min-h-[5rem] w-full border-2"
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormItem>
      <TextBody type={1}>
        {orderLine
          ? 'If you need to change any creatives on this order line, please upload them as well.'
          : 'If any files are needed for this request, please upload them as well.'}
      </TextBody>
      <FormItem
        htmlFor={orderLine ? 'creatives' : 'support'}
        label={orderLine ? 'Creatives' : 'Support'}
        wrapperClassname="flex-col"
      >
        <Uploader
          accepts="support"
          onDrop={async (files: PreviewType[]) => {
            setUploadMsgs([])
            files.forEach(async (currentFile: PreviewType) => {
              const fileWithType = handleCheckFile(currentFile)
              if (fileWithType) {
                setFiles((prev) => {
                  return [...prev, fileWithType]
                })
              }
            })
          }}
        >
          <Fa
            className="mt-auto pb-1"
            icon="cloud-arrow-up"
            type="duotone"
            size={3}
          />
          <TextHeader type={6} className="pb-1 normal-case">
            Click to upload
          </TextHeader>
        </Uploader>
        {uploadMsgs?.map((uploadMsg, i) => (
          <div
            className={classNames(
              'SupportRequestModal__upload-msg animate-slidedown rounded p-2 text-center font-bold',
              {
                'text-warning bg-warning-50': uploadMsg.type === 'warning',
                'text-danger bg-danger-50': uploadMsg.type === 'error',
              },
            )}
            key={`Error_${uploadMsg.fileName}_${i}`}
          >
            {uploadMsg.fileName}: {uploadMsg.msg}
          </div>
        ))}
        {files.length > 0 && (
          <SupportRequestUploadList
            files={files}
            onRemove={(fileId) => {
              setFiles((prev) =>
                prev.filter((prevFile) => prevFile.id !== fileId),
              )
            }}
          />
        )}
      </FormItem>
      <TextBody>
        Note: If any files are needed for this request please attach them here.
      </TextBody>
      <TextBody>
        Allowed File types: .avi, .mp4, .mpeg, .ogv, .ts, .webm, .3gp, .3g2,
        .mov, .jpeg, .gif, .png
      </TextBody>
      <TextBody>
        If you need to upload a different type of file, fill the description and
        submit the ticket, an agent will get in touch with you. Alternatively,
        email us directly on support@eltoro.com
      </TextBody>
      <TextBody type={1}>
        Responses to this request by our AdOps team will be sent via email.
      </TextBody>
      {errorSubmitting && (
        <div className="SupportRequestModal__error-submitting animate-slidedown text-danger bg-danger-50 rounded p-2 text-center font-bold">
          There was an error submitting your support request, please try again.
          If the error persists, please reach out to{' '}
          <a href="mailto:support@eltoro.com">support@eltoro.com</a>
        </div>
      )}
      <div className="SupportRequestModal__footer-btns flex justify-end gap-2">
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleSubmit}
          disabled={!description || sendingRequest}
        >
          Send request
        </Button>
      </div>
    </Modal>
  )
}
