import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import {
  Targetjobservicev1Audience,
  Targetjobservicev1Timeframe,
} from 'next-gen-sdk'
import { Button, Fa, Text } from '@eltoro-ui/components'
import { Map } from '@eltoro-ui/map'
import { getLatestUTCVRJobStopTime } from 'Helpers'
import './LocationSection.scss'
import { Feature } from 'geojson'
import { dayjs } from 'Tools/dateUtils'

type LatLngTuple = [number, number]

const TimeframeRow = ({
  onSave,
  rowNumber,
  defaultStart,
  defaultEnd,
  defaultEdited,
}: {
  onSave?: ({ start, stop }: { start: Date; stop: Date }) => Promise<void>
  rowNumber?: number
  defaultStart?: Date
  defaultEnd?: Date
  defaultEdited?: boolean
}) => {
  const [start, setStart] = useState<Date | undefined>(defaultStart)
  const [stop, setStop] = useState<Date | undefined>(defaultEnd)
  const [edited, setEdited] = useState(defaultEdited || false)

  // TODO: The editing functionality is not being used currently, as the user can use the VR editor to do this.
  // If we re-enable it, we need to look at the TimeFrames component to ensure the
  // date/time is correct and in UTC time. I only updated the buffer here.
  const fullDayBuffer = getLatestUTCVRJobStopTime()
  const sixMonthsAgo = dayjs().tz().subtract(6, 'month').toDate()

  useEffect(() => {
    if (defaultStart) setStart(defaultStart)
    if (defaultEnd) setStop(defaultEnd)
  }, [defaultStart, defaultEnd])

  return (
    <>
      <Text className="text-grey-600 text-s">Timeframe {rowNumber}</Text>
      <div className="LocationSection__time-edit bg-gray relative my-2 flex flex-wrap rounded-sm p-2">
        <div>
          <Text className="text-s font-bold">Start:</Text>
          {/* @ts-ignore */}
          <ReactDatePicker
            className="text-s"
            onChange={(e) => {
              if (e) {
                setEdited(true)
                setStart(e)
              }
            }}
            maxDate={fullDayBuffer}
            minDate={sixMonthsAgo}
            selected={start}
            dateFormat="MM/dd/yyyy h:mm aa"
            disabled
          />
        </div>
        <div>
          <Text className="text-s font-bold">Stop:</Text>
          {/* @ts-ignore */}
          <ReactDatePicker
            className="text-s"
            onChange={(e) => {
              if (e) {
                setEdited(true)
                setStop(e)
              }
            }}
            dateFormat="MM/dd/yyyy h:mm aa"
            maxDate={fullDayBuffer}
            minDate={sixMonthsAgo}
            selected={stop}
            disabled
          />
        </div>
        {edited && (
          <div className="mt-2 flex w-full flex-wrap items-center justify-center gap-2">
            <Button
              type="button"
              size="s"
              className="animate-slidedown"
              onClick={() => {
                if (start && stop && onSave)
                  onSave({ start, stop }).then(() => setEdited(false))
              }}
              iconLeft={<Fa icon="floppy-disk" size={1} />}
            >
              Save
            </Button>
            <Button
              type="button"
              size="s"
              className="animate-slidedown"
              onClick={() => {
                setStart(defaultStart)
                setStop(defaultEnd)
                setEdited(false)
              }}
              iconLeft={<Fa icon="xmark" size={1} />}
            >
              Cancel
            </Button>
          </div>
        )}
        {/* TODO: allow deleting the time */}
        {/* <Button
          size="s"
          iconOnly={<Fa icon="xmark" size={1} />}
          className="absolute top-1 right-1 border-none"
          disabled
        /> */}
      </div>
    </>
  )
}

export const LocationSection = ({
  job,
  timeFrames,
  mapFeatures,
}: {
  job: Targetjobservicev1Audience
  timeFrames?: Targetjobservicev1Timeframe[]
  mapFeatures?: Feature[]
}) => {
  // const { currentOrg, targetServiceApi, tok } = useAppContext()
  // const { refetch } = useLibraryContext()
  const [DBFeatures, setDBFeatures] = useState<Feature[] | []>([])
  useEffect(() => {
    if (mapFeatures && mapFeatures.length > 0) {
      setDBFeatures(mapFeatures)
    }
  }, [mapFeatures])

  return (
    <div className="LocationSection">
      <p>
        <strong>Location</strong>
      </p>
      <div className="bg-grey-100 text-primary-400 my-2 h-1/2">
        <Map
          center={[38.194706, -85.71053] as LatLngTuple}
          markerHtml="<div/>"
          tileProvider="Esri.WorldTopoMap"
          editable={false}
          ClientFeatures={
            mapFeatures && DBFeatures.length === 0 ? mapFeatures : DBFeatures
          }
          onShapeChange={(data) => {
            if (data && data.length > 0) {
              setDBFeatures(data)
            }
          }}
          hideSearch
          hideButtons
          cutMode={false}
          uniqueMapId={`${Math.random() * 90}`}
          showSmallZoomButton
        />
      </div>
      {job.type === 'AUDIENCE_TYPE_VR' &&
        timeFrames &&
        timeFrames?.map((currentFrame, index) => {
          return (
            <TimeframeRow
              key={`${currentFrame.start}${currentFrame.stop}-${index}`}
              rowNumber={timeFrames.length > 1 ? index + 1 : undefined}
              defaultStart={
                currentFrame.start ? new Date(currentFrame.start) : undefined
              }
              defaultEnd={
                currentFrame.stop ? new Date(currentFrame.stop) : undefined
              }
            />
          )
        })}
    </div>
  )
}
